// @ts-check
import React, { lazy } from "react";
import DashboardComponent from "@app/dashboard/dashboardComponent";
const AdminContainer = lazy(() => import(/* webpackChunkName: "AdminWorkspace" */ "@app/workspace/admin/adminContainer"));
const SuperAdminContainer = lazy(() => import(/* webpackChunkName: "SuperAdminWorkspace" */ "@app/workspace/superadmin/superAdminContainer"));

import adminMenuFixture from '@app/workspace/admin/fixtures/adminMenu.json';
import superAdminMenuFixture from '@app/workspace/superadmin/fixtures/superAdmin.json';


export const dashboardStaticWorkspaceConfig = {
    id: "dashboard",
    displayName: 'Dashboard',
    element: <DashboardComponent />,
    specifications: {
        layoutConfig: {
            parentPageTitle: "Dashboard",
            "icon": "dashboard"
        },
        order: 0,
        path: "/dashboard",
    }
};

export const adminStaticWorkspaceConfig = {
    id: 'admin',
    displayName: "Admin",
    element: <AdminContainer />,
    primaryEntity: '',
    specifications: {
        layoutConfig: {
            parentPageTitle: "Admin",
            "icon": "admin",
            menuDrawer: {
                title: 'Admin',
                menuItemList: adminMenuFixture.drawerContent
            },
        },
        order: 99,
        path: "/admin/*",
    }

};

/**
 * @type {SweftWorkspaceStaticConfig}
 */
export const superAdminStaticWorkspaceConfig = {
    id: 'superAdmin',
    displayName: "SuperAdmin",
    element: <SuperAdminContainer />,
    specifications: {
        layoutConfig: {
            parentPageTitle: "Super Admin",
            "icon": "superadmin",
            menuDrawer: {
                title: 'Super Admin',
                menuItemList: superAdminMenuFixture.drawerContent
            }
        },
        order: 100,
        path: "/superadmin/*",
    }
};
