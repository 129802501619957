// @ts-check
import { findInList } from "@app/common/utils";
import { generateDataActorId } from "@app/data/machine/utils";

/**
 * Generates and returns state selector function for getting the entity data actor of the provided entity
 * @prop {string} entity
 * @prop {Record<unknown, unknown>} config
 * @returns {(function(*): (*))|*}
 */
export const generateDataActorSelector = ({ dataActorOptions = {}, dataActorId } = {}) => {
    let relevantDataActorId = dataActorId;
    if (!relevantDataActorId) {
        relevantDataActorId = generateDataActorId({ dataActorOptions });
    }
    return function (state) {
        const { context } = state;
        const dataActorObj = findInList(context.dataActorList, { dataActorId: relevantDataActorId });
        if (!!dataActorObj === true) {
            return dataActorObj;
        }
        return state.context.unknownDataActor;
    };
};
