import React from "react";
import { Skeleton } from "antd";

export const SkeletonAlertsList = () => {
    return (
        <div className="flex flex-col gap-3">
            {[...Array(10).keys()].map((n) => (
                <Skeleton.Button key={n} active={true} size="large" shape="default" block={true}/>
            ))}
        </div>
    );
};
