import React, { createContext, useContext, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { createMappedBobj } from "@app/data/utils";
import { useInterpret } from "@xstate/react";
import { receiveSampleMachine } from "@app/grid/renderers/controls/GridCTAReceiveSample/machine";
/* eslint max-lines-per-function: "Warn" */
/* eslint max-lines: "Warn" */
const ReceiveSamplesContext = createContext();

export const useReceiveSampleContext = () => useContext(ReceiveSamplesContext);

export const ReceiveSamplesWrapper = ({ children }) => {
    const receiveSamplesMachineService = useInterpret(receiveSampleMachine, { devTools: true });

    const samplesObjectDataCreateCall = ({ sampleEntity, sampleCreatedDateAttribute, send, gidAttributesList, data }) => {
        const objectToCreate = {};
        objectToCreate.id = uuidv4();
        objectToCreate.entity = sampleEntity;
        objectToCreate.createdDate = new Date().getTime();
        objectToCreate.product = data.id;
        if (sampleCreatedDateAttribute) {
            objectToCreate[sampleCreatedDateAttribute] = objectToCreate.createdDate;
        }

        send({
            type: "CREATE_DATA",
            object: { ...objectToCreate },
            expectedCreatedObject: {
                ...objectToCreate
            },
            gidAttributesList,
        });

        return objectToCreate;
    };

    const createMappedBobjOnProductEntity = ({ objectCreated, bobjwithGeneratedId, data, samplesEntityMappedColumnsList, dataMachineService, dataActorId }) => {
        createMappedBobj({
            bobj: { ...objectCreated, ...bobjwithGeneratedId, product: data },
            mappedAttributeList: samplesEntityMappedColumnsList,
            dataMachineService,
            mappedBobjDataActorId: dataActorId
        });
    };


    const contextValue = useMemo(() => ({
        samplesObjectDataCreateCall,
        createMappedBobjOnProductEntity,
        receiveSamplesMachineService
    }), [receiveSamplesMachineService]);

    return (
        <ReceiveSamplesContext.Provider value={contextValue}>{children}</ReceiveSamplesContext.Provider>
    );
};
