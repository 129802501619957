import ConfigurationService from "@app/services/configurationService";
import { generateOptimisticDataMachine } from "@app/data/machine/actors/optimisticDataActorMachine";
import { sleep } from "@app/common/utils";
import { FRESHNESS_PROCESSING_STRATEGY } from "@app/data/utils";

const rolesConfigMachineGeneratorProps = {
    type: "rolesConfig",
    keyProperty: "configurationId",
    createMachineOptions: {
        services: {
            createService: async (context) => {
                const { object } = context;
                await ConfigurationService.saveRoleConfig(object);
                return { newObject: object };
            }
        }
    },
    updateMachineOptions: {
        services: {
            updateService: async (context) => {
                const { object } = context;
                await ConfigurationService.saveRoleConfig(object);
                return { newObject: object };
            }
        }
    },
    deleteMachineOptions: {
        services: {
            deleteService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    ConfigurationService.removeRoleConfig(object).then(() => {
                        resolve({ deletedObject: object });
                    });
                });
            }
        }
    },
    services: {
        loadService: async () => {
            return new Promise((resolve) => {
                ConfigurationService.getRoleConfigList().then((roleConfigList) => {
                    resolve({ loadedData: roleConfigList });
                });
            });
        },
        loadDataFilteredForTransientData: async () => {
            return new Promise((resolve) => {
                ConfigurationService.getRoleConfigList().then((roleConfigList) => {
                    resolve({ loadedData: roleConfigList });
                });
            });
        }
    },
    transientConfig: {
        transientProperty: "modifiedTimestamp",
        transientValueOnCreate: () => 0,
        transientValueOnUpdate: (obj) => obj.modifiedTimestamp,
        freshnessProcessingStrategy: FRESHNESS_PROCESSING_STRATEGY.KEEP_TRANSIENT
    }
};

export const rolesConfigDataActorMachine = generateOptimisticDataMachine(rolesConfigMachineGeneratorProps);
