import { generateEntityDataActorMachine } from "@app/data/machine/actors/entityDataActorMachine/entityDataActorMachine";
import { generateEntitySavedQueriesCountsMachine } from "@app/data/machine/actors/entitySavedQueriesCountsDataActor";
import { generateEntitySavedQueryDataActorMachine } from "@app/data/machine/actors/entitySavedQueryDataActorMachine";
import { generateDataActorId } from "@app/data/machine/utils";

export const dataActorBuilder = ({ dataActorOptions = {} }) => {
    const { dataActorType } = dataActorOptions;
    const dataActorId = generateDataActorId({ dataActorOptions });
    const dataActorObj = {
        dataActorId,
        dataActorType
    };
    dataActorOptions.dataActorId = dataActorId;
    switch (dataActorType) {
        case 'entity':
            dataActorObj.ref = generateEntityDataActorMachine({ dataActorOptions });
            break;
        case 'entitySavedQuery':
            dataActorObj.ref = generateEntitySavedQueryDataActorMachine({ dataActorOptions });
            break;
        case 'entitySavedQueryCounts':
            dataActorObj.ref = generateEntitySavedQueriesCountsMachine({ dataActorOptions });
            break;
        default:
            break;
    }

    return dataActorObj;
};
