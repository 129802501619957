// @ts-check
import React from 'react';
import { Outlet } from 'react-router-dom';
import { EmailPasswordAuth } from "supertokens-auth-react/recipe/emailpassword";

/**
 * An embedded approach for protected routes
 * https://supertokens.com/docs/emailpassword/common-customizations/embed-sign-in-up-form
 *
 */

/**
 * Goal is to always only display the contents, or else navigate to logout
 * @returns {JSX.Element}
 */
export const ProtectedRoute = ({ children }) => {
    return (
        <EmailPasswordAuth>
            {children ? children : <Outlet/>}
        </EmailPasswordAuth>
    );
};
