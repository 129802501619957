import React from "react";
import {DashboardModule} from "@app/dashboard/controls/DashboardModule";
import {SkeletonAlertsList} from "@app/dashboard/controls/DashboardModuleAlerts/SkeletonAlertsList";
import {DashboardModuleAlertTile} from "@app/dashboard/controls/DashboardModuleAlerts/DashboardModuleAlertTile";
import {
    DashboardModuleSpecialtyToggle
} from "@app/dashboard/controls/DashboardModuleAlerts/DashboardModuleSpecialtyToggle";
import {DashboardModuleAlertTileList} from "@app/dashboard/controls/DashboardModuleAlerts/DashboardModuleAlertTileList";
import {useCurrentUserRole} from "@app/auth/useCurrentUserRole";
import {findInList} from "@app/common/utils";

export const DashboardModuleAlertsComponent = ({ useAlerts, useAlertStatus, useSpecialty } = {}) => {
    const { alerts } = useAlerts();

    const {currentUserRole} = useCurrentUserRole();

    const uniqueAlertList = Array.from(new Set(currentUserRole?.alertList.map(({ id }) => id))).map((id) => currentUserRole?.alertList.find((alert) => alert.id === id));

    return <DashboardModule className="col-start-1 col-end-6 row-span-6">
        <DashboardModule.Header>
            <DashboardModule.Title>Priority Alerts</DashboardModule.Title>
            <div>
                <DashboardModuleSpecialtyToggle useSpecialty={useSpecialty}/>
            </div>
        </DashboardModule.Header>
        <DashboardModule.Body>
            {uniqueAlertList === null ?
                <SkeletonAlertsList/> :
                <DashboardModuleAlertTileList>
                    {uniqueAlertList?.map(
                        (roleAlertObj, index) => {
                            const alert = findInList(alerts, { alertId: roleAlertObj?.id });
                            return (
                                alert && <DashboardModuleAlertTile key={alert?.path ?? index} alert={alert} useAlertStatus={useAlertStatus}/>
                            );
                        }
                    )}
                </DashboardModuleAlertTileList>
            }

        </DashboardModule.Body>
    </DashboardModule>;
};
