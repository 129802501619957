// @ts-check
import { useConfigDataActor } from "@app/data/config/hooks/useConfigDataActor";

/** @typedef {{roleId: string, displayName:string, enabled:boolean}} systemRole*/

/** @typedef {{roleId: string, displayName: string, state: 'On'|'Off'}} configRoleData */

/**
 * Get the application roles that exist in the system
 * @note NOT the user's roles of the moment
 * @returns {systemRole[]} roleList of all available roles that the app has
 **/
const useSystemRoles = () => {
    const { dataActorData: rolesConfigList } = useConfigDataActor({ config: "roles" });
    const justSystemRoles = rolesConfigList?.map((systemRole) => {
        return {
            roleId: systemRole.roleId,
            displayName: systemRole.displayName,
            enabled: systemRole.state === 'On',
        };
    }) ?? [];
    const standardSuperadmin = {
        roleId: 'SUPERADMIN',
        displayName: 'SuperAdmin',
        enabled: true,
    };
    justSystemRoles.push(standardSuperadmin);
    return justSystemRoles;
};

export default useSystemRoles;
