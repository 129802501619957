import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";
import { useConfigDataActor } from "@app/data/config/hooks/useConfigDataActor";
import { useCallback, useMemo } from "react";

export const useStageStatusOrder = () => {
    const { workspaceList } = useWorkspaces();
    const { dataActorSend: sendToWorkspacesDataActor } = useConfigDataActor({ config: "workspaces" });

    const configuredWorkspace = useMemo(() => {
        return workspaceList.find(({ specifications = {} }) => {
            return specifications?.moduleConfigMap?.stageStatusModuleConfig?.enabled;
        });
    }, [workspaceList]);

    const stageStatusOrder = useMemo(() => {
        return configuredWorkspace?.specifications?.moduleConfigMap?.stageStatusModuleConfig?.options?.stageStatusOrderList ?? [];
    }, [configuredWorkspace?.specifications?.moduleConfigMap?.stageStatusModuleConfig?.options?.stageStatusOrderList]);

    const updateStageStatusOrder = useCallback(({ newStageStatusOrderList }) => {
        const field = 'specifications';
        const value = { ...configuredWorkspace.specifications };
        value.moduleConfigMap.stageStatusModuleConfig.options = {
            ...(value.moduleConfigMap.stageStatusModuleConfig.options ? value.moduleConfigMap.stageStatusModuleConfig.options : {} ),
            stageStatusOrderList: newStageStatusOrderList
        };

        const nextWorkspaceObject = {
            ...configuredWorkspace,
            specifications: value
        };

        sendToWorkspacesDataActor({ type: "UPDATE_DATA", object: { ...nextWorkspaceObject }, fieldBeingUpdated: { [field]: value }, expectedUpdateResult: { [field]: value } });
    }, [configuredWorkspace, sendToWorkspacesDataActor]);


    return useMemo(() => ({
        configuredWorkspace,
        stageStatusOrder,
        updateStageStatusOrder
    }), [configuredWorkspace, stageStatusOrder, updateStageStatusOrder]);
};
