import { assign, createMachine, pure, send, spawn } from "xstate";
import { unknownDataActorMachine } from "@app/data/machine/actors/unknownDataActorMachine";
import { entitySchemaDataActorMachine } from "@app/data/machine/actors/entitySchemaDataActor";
import { attributeSchemaDataActorMachine } from "@app/data/machine/actors/attributeSchemaDataActor";
import { workspacesConfigDataActorMachine } from "@app/data/machine/actors/workspacesConfigDataActor";
import { rolesConfigDataActorMachine } from "@app/data/machine/actors/rolesConfigDataActor";
import { viewsConfigDataActorMachine } from "@app/data/machine/actors/viewsConfigDataActor";
import { generateAlertsConfigDataActorMachine } from "@app/data/machine/actors/alertsConfigDataActor";
import { entitlementsDataActorMachine } from "@app/config/machines/entitlementsDataActorMachine";

export const configDataMachineInitialContext = {
    unknownDataActor: null,
    entitySchemaDataActor: null,
    attributeSchemaDataActor: null,
    entitlementsDataActor: null,
    configDataActorList: []
};

export const configDataMachine = createMachine({
    id: "configDataMachine",
    context: configDataMachineInitialContext,
    initial: "init",
    states: {
        init: {
            entry: "spawnInitialActors",
            always: "done",
        },
        done: {}
    },
    on: {
        ENTITY_DATA_LOADED: {
            actions: "sendToEntitlementsDataActor"
        },
        NEW_USER_OBJECT: {
            actions: "sendUserToEntitlementsDataActor"
        }
    }
},
{
    actions: {
        spawnInitialActors: assign({
            unknownDataActor: () => ({
                type: "unknownDataActor",
                ref: spawn(unknownDataActorMachine)
            }),
            entitySchemaDataActor: () => ({
                type: "entitySchemaDataActor",
                ref: spawn(entitySchemaDataActorMachine),
            }),
            attributeSchemaDataActor: () => ({
                type: "attributeSchemaDataActor",
                ref: spawn(attributeSchemaDataActorMachine)
            }),
            configDataActorList: () => {
                return [{
                    type: "config",
                    config: "workspaces",
                    ref: spawn(workspacesConfigDataActorMachine)
                },
                {
                    type: "config",
                    config: "roles",
                    ref: spawn(rolesConfigDataActorMachine)
                },
                {
                    type: "config",
                    config: "views",
                    ref: spawn(viewsConfigDataActorMachine)
                },
                {
                    type: "config",
                    config: "alerts",
                    ref: spawn(generateAlertsConfigDataActorMachine())
                }];
            },
            entitlementsDataActor: () => ({
                type: "entitlementsDataActor",
                ref: spawn(entitlementsDataActorMachine)
            })
        }),
        sendToEntitlementsDataActor: pure((context, event) => {
            const { actorType, loadedData } = event;
            return send({ type: "CONFIG_LOADED", loadedConfigName: actorType, loadedConfigValue: loadedData.map((obj) => obj?.data ? obj.data : obj) }, { to: context.entitlementsDataActor.ref });
        }),
        sendUserToEntitlementsDataActor: pure((context, event) => {
            const { user } = event;
            return send({ type: "NEW_USER_OBJECT", user }, { to: context.entitlementsDataActor.ref });
        })
    }
});

