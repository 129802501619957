import React from 'react';
import './dashboard.css';

import { DashboardModuleStageStatus } from "@app/dashboard/controls/DashboardModuleStageStatus";
import { DashboardModuleMessages } from "@app/dashboard/controls/DashboardModuleMessages";
import { DashboardModuleAlertsContainer } from "@app/dashboard/controls/DashboardModuleAlerts/DashboardModuleAlertsContainer";

/**
 * Core display, needs to be fast for initial load, modify carefully
 * @returns {JSX.Element}
 */
const DashboardComponent = () => {
    return (
        <div className="bg-gray-200 p-10 gap-10 grid grid-cols-12 grid-rows-6 h-full w-full overflow-hidden">
            <DashboardModuleAlertsContainer/>
            <DashboardModuleMessages/>
            <DashboardModuleStageStatus/>
        </div>

    );
};

DashboardComponent.propTypes = {};

export default DashboardComponent;
