import { chunk } from 'lodash';
import { VanguardService } from "@app/services/vanguardService";

export const loadEntityDataFilteredForTransientData = async ({ context }, { entity, schemaTreeExclusionList, receiveEvaluatedBobj, entityFormulaPathMap }) => {
    const { transientData, projectionAttributeList: projectionAttributeListFromContext } = context;

    const transientObjectIdList = transientData.map((transientObj) => transientObj?.object?.id);
    if (transientObjectIdList.length === 0) {
        return {
            loadedData: []
        };
    }

    let projectionAttributeListWithoutFormulaList = projectionAttributeListFromContext?.filter((projection) => !entityFormulaPathMap?.[projection]) ?? [];
    if (projectionAttributeListWithoutFormulaList.length === 0) {
        projectionAttributeListWithoutFormulaList = [`${entity}.id`];
    }

    // Chunk the transientObjectIdList into chunks of 150 items each using lodash
    const chunkedObjectIdList = chunk(transientObjectIdList, 100);
    const errors = [];
    const promises = chunkedObjectIdList.map(async (objectIdChunk) => {
        const preFilterEntityMap = {
            [entity]: [{
                attribute: "id",
                operator: "SweftOperators.SweftInList",
                value: objectIdChunk
            }]
        };

        try {
            return await VanguardService.data.getBusinessObjectsOfEntity({
                entity,
                schemaTreeExclusionList,
                projectionAttributeList: projectionAttributeListWithoutFormulaList,
                receiveEvaluatedBobj,
                preFilterEntityMap
            });
        } catch (error) {
            errors.push(error);
            // Instead of returning an empty array, throw the error to be caught by Promise.allSettled
            throw error;
        }
    });

    const results = await Promise.allSettled(promises);
    const successfulResults = results.filter(result => result.status === 'fulfilled').map(result => result.value).flat();

    if (errors.length > 0) {
        // Handle or log the errors as needed
        const errorMessages = errors.map(error => error.message || "An error occurred").join(", ");
        throw new Error(`Errors occurred in the following chunks: ${errorMessages}`);
    }

    return {
        loadedData: successfulResults,
        requestProjectionAttributeList: projectionAttributeListFromContext,
    };
};
