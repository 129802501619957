// @ts-check
import React from "react";
import { LoadingArea } from "@app/auth/login/LoadingArea";
import { Navigate } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

/**
 * This is a special exception page that simply displays the login ui
 * All other pages just get wrapped in ProtectedRoute,
 * and this one does too
 */
export const SimpleLogin = ({ className }) => {
    const session = useSessionContext();

    // @ts-ignore
    if (session.doesSessionExist) {
        return <Navigate replace to="/dashboard" />;
    }

    return <LoadingArea className={`simple-login-page ${className ?? ''}`} />;
};

export default SimpleLogin;
