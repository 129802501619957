import React from 'react';
import defaultLogo from '../../../images/horizontal-logo.png';

/**
 * Add a custom logo for the login area, fallback to sweft default
 * @param {*} param0
 * @returns {JSX.Element}
 */
export const LoginHeader = ({ logo = defaultLogo, altText, variant = 'login-header', children = undefined }) => {
    return <div>
        <header>
            <h1 className={variant}>
                <img className="mx-auto" src={logo} alt={altText} />
            </h1>
        </header>
        {children}
    </div>;
};
