import { SweftError } from "@app/common/SweftError";
import { formulaProcessors, isObject } from "@app/common/utils";

export const mapEntityData = ({ entityDataMappingConfigObj = {}, bobjList = [] } = {}) => {
    const { mappingFunctionType, mappingFunction } = entityDataMappingConfigObj;
    if (mappingFunctionType === 'jsonata') {
        return bobjList.reduce((mappedDataAgg, nextEntryToMap) => {
            const evaluatedEntry = formulaProcessors.jsonata(nextEntryToMap, mappingFunction);
            if (Array.isArray(evaluatedEntry)) {
                return [
                    ...mappedDataAgg,
                    ...evaluatedEntry
                ];
            }
            if (isObject(evaluatedEntry)) {
                return [...mappedDataAgg, evaluatedEntry];
            }
            return mappedDataAgg;
        }, []);
    }
    throw new SweftError(`Mapping function type '${mappingFunctionType}' not available for mapping data`);
};
