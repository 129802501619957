import React from "react";
import { RingLoader } from "react-spinners";

/**
 * The split out ring-loader area for use beyond the loading screens
 * @returns {JSX.Element}
 */
export const LoadingSpinner = () => {
    return (
        <div className="sweft-loading loading-screen">
            <RingLoader
                color="#36D7B7"
                loading={true}
            />
        </div>
    );
};
