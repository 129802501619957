// @ts-check
/* global BUILD_VER */
/* TODO: Remove FE Change */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as reactRouterDom from "react-router-dom";
import VersionNotification from './common/version-notification';
// Supertokens packages
import { SuperTokensWrapper, getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import { AuthWrapper } from "@app/auth/context/useAuth";
import SimpleLogin from './auth/login/SimpleLogin';
import Logout from './auth/logout/logout';
import { ProtectedRoute } from './auth/util/protectedRoute';
import SweftLayout from './layout/sweftLayout';
import { DataContextsWrapper } from "@app/contexts/dataContexts";
import { StatusWrapper } from '@app/common/StatusInfo/StatusInfo';
import { FlagsProvider } from 'flagged';
import { getFeatureFlags, isFlagOn, flagNames } from "@app/common/featureFlags";
import { inspect } from '@xstate/inspect';

/**
 * Displays the xstate inspector
 */

if (isFlagOn(flagNames.xstateInspectorEnabled)) {
    inspect({
        iframe: false
    });
}

import '../css/global.css';
import '../tailwind-styles/tailwind.css';
import '../css/antd.less';
import { UsersnapProvider } from './common/userSnap/userSnapContext';
import { Button } from 'antd';
/**
 * ------------------------------------------------------------
 * NOTE: Any changes to these global css/less files may need
 * to be duplicated elsewhere where global, antd, tailwind, etc are used.
 * ------------------------------------------------------------
 */

if (module.hot) {
    module.hot.accept();
}

/**
 * ------------------------------------------------------------
 * Currently using SuperTokensWrapper directly around app, refactor before merge
 */
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <FlagsProvider features={getFeatureFlags()}>
        <UsersnapProvider>
        </UsersnapProvider>
        <BrowserRouter>
            <VersionNotification buildVersion={
                // @ts-ignore
                BUILD_VER}
            />
            <SuperTokensWrapper>
                <AuthWrapper>
                    <Routes>
                        {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
                        <Route
                            // @ts-ignore
                            index path="/login" element={<ProtectedRoute><SimpleLogin /></ProtectedRoute>}
                        />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/status" element={<StatusWrapper />} />
                        <Route
                            path="/*" element={
                                <ProtectedRoute>
                                    <DataContextsWrapper>
                                        <SweftLayout />
                                    </DataContextsWrapper>
                                </ProtectedRoute>
                            }
                        />

                    </Routes>
                </AuthWrapper>
            </SuperTokensWrapper>
        </BrowserRouter>
    </FlagsProvider>,
);
