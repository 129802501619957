import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";

export const LayoutContext = createContext();

export const useLayout = () => useContext(LayoutContext);

export const LayoutContextWrapper = ({ children }) => {
    const { currentWorkspace } = useWorkspaces();
    const [parentPageTitle, setParentPageTitle] = useState(null);
    const [childPageTitle, setChildPageTitle] = useState(null);
    useEffect(() => {
        const { specifications = {} } = currentWorkspace ?? {};
        const { layoutConfig = {} } = specifications ?? {};
        const { parentPageTitle: workspaceParentPageTitle = null, childPageTitle: workspaceChildPageTitle = null } = layoutConfig;
        setParentPageTitle(workspaceParentPageTitle);
        setChildPageTitle(workspaceChildPageTitle);
    },[currentWorkspace]);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [rightDrawerOpen, setRightDrawerOpen] = useState(false);

    const [isFullscreenWorkspace, setIsFullscreenWorkspace] = useState(false);

    const toggleFullscreenWorkspace = useCallback(() => {
        setIsFullscreenWorkspace(!isFullscreenWorkspace);
    }, [isFullscreenWorkspace]);

    const previousParentPageTitle = useRef(parentPageTitle);
    useEffect(() => {
        if (previousParentPageTitle.current !== null) {
            setChildPageTitle(null);
        }
        previousParentPageTitle.current = parentPageTitle;
    }, [parentPageTitle]);

    useEffect(() => {
        let nextPageTitle = `Sweft`;
        if (childPageTitle) {
            nextPageTitle = `${childPageTitle} - ${nextPageTitle}`;
        }
        if (parentPageTitle) {
            nextPageTitle = `${parentPageTitle} - ${nextPageTitle}`;
        }
        document.title = nextPageTitle;
    }, [parentPageTitle, childPageTitle]);

    const contextValue = useMemo(() => ({
        parentPageTitle,
        childPageTitle,
        setParentPageTitle,
        setChildPageTitle,
        drawerOpen,
        setDrawerOpen,
        rightDrawerOpen,
        setRightDrawerOpen,
        isFullscreenWorkspace,
        toggleFullscreenWorkspace
    }), [parentPageTitle, childPageTitle, drawerOpen, rightDrawerOpen, isFullscreenWorkspace, toggleFullscreenWorkspace]);

    return (
        <LayoutContext.Provider value={contextValue}>
            { children }
        </LayoutContext.Provider>
    );
};
