import React from 'react';
import { NavLink } from 'react-router-dom';
import Drawer from './drawer';
import './menuDrawer.less';
import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";

const MenuDrawer = () => {
    const { currentWorkspace } = useWorkspaces();
    if (!currentWorkspace?.specifications?.layoutConfig?.menuDrawer) {
        return null;
    }
    const { title, menuItemList } = currentWorkspace.specifications.layoutConfig.menuDrawer;
    return (
        <Drawer title={title}>
            <div id="layout-drawer-menu">
                {(menuItemList.length > 0) &&
                    <ul className="layout-drawer-item-list">
                        {menuItemList.map((item) => (
                            <li key={item.displayName}>
                                <NavLink
                                    title={item.displayName}
                                    to={item.path}
                                    className={({ isActive }) => isActive ? 'layout-drawer-item-active' : ''}
                                >
                                    {item.displayName}
                                </NavLink>

                            </li>)
                        )}
                    </ul>
                }
            </div>
        </Drawer>
    );
};

export default MenuDrawer;
