import ConfigurationService from "@app/services/configurationService";
import { generateOptimisticDataMachine } from "@app/data/machine/actors/optimisticDataActorMachine";
import { FRESHNESS_PROCESSING_STRATEGY } from "@app/data/utils";


const viewsConfigMachineGeneratorProps = {
    type: "viewsConfig",
    keyProperty: "configurationId",
    createMachineOptions: {
        services: {
            createService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    ConfigurationService.saveViewConfig(object).then(() => {
                        resolve({ newObject: object });
                    });
                });
            }
        }
    },
    updateMachineOptions: {
        services: {
            updateService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    ConfigurationService.saveViewConfig(object).then(() => {
                        resolve({ newObject: object });
                    });
                });
            }
        }
    },
    deleteMachineOptions: {
        services: {
            deleteService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    ConfigurationService.removeViewConfig(object).then(() => {
                        resolve({ deletedObject: object });
                    });
                });
            }
        }
    },
    services: {
        loadService: async () => {
            return new Promise((resolve) => {
                ConfigurationService.getViewConfigList().then((viewConfigList) => {
                    resolve({ loadedData: viewConfigList });
                });
            });
        },
        loadDataFilteredForTransientData: async () => {
            return new Promise((resolve) => {
                ConfigurationService.getViewConfigList().then((viewConfigList) => {
                    resolve({ loadedData: viewConfigList });
                });
            });
        }
    },
    transientConfig: {
        transientProperty: "modifiedTimestamp",
        transientValueOnCreate: () => 0,
        transientValueOnUpdate: (obj) => obj.modifiedTimestamp,
        freshnessProcessingStrategy: FRESHNESS_PROCESSING_STRATEGY.KEEP_TRANSIENT

    }
};

export const viewsConfigDataActorMachine = generateOptimisticDataMachine(viewsConfigMachineGeneratorProps);
