import axios from 'axios';
import { headers } from "@app/services/headers";
import Session from "supertokens-auth-react/recipe/session";

Session.addAxiosInterceptors(axios);

/**
 * Used for wrapping around axios requests
 * @param {*} option
 * @returns {object|void}
 */
const request = async (option) => {
    option.headers = option.headers || headers;

    try {
        const response = await axios(option);

        if (response.data) {
            return response;
        }
    } catch (error) {
        console.log(`API error:${ error}`);
        throw error;
    }
};

export default request;
