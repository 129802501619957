import { assign, createMachine, sendParent } from "xstate";

/**
 * A machine for calling a createService and passing in an object to update via the context
 *
 * @param context - The object that will be created
 * @param createInBatchMachineOptions - Machine options (actions, services, guards)
 * @returns {StateMachine}
 */
export const generateCreateInBatchDataMachine = ({ context: machineContext, createInBatchMachineOptions }) => {
    return createMachine({
        predictableActionArguments: true,
        id: `createInBatchData`,
        context: machineContext,
        initial: "checkIfGenIdIsNeeded",
        states: {
            checkIfGenIdIsNeeded: {
                always: [
                    {
                        target: "gettingGeneratedIdValuesForObject",
                        cond: "objectNeedsGeneratedIdValues"
                    },
                    {
                        target: "callingCreateService"
                    }
                ]
            },
            gettingGeneratedIdValuesForObject: {
                invoke: {
                    src: "generatedIdService",
                    onDone: {
                        actions: ["setObjectListInContext", "sendToParentTransientObjectWithGeneratedId"],
                        target: 'callingCreateService',
                    },
                },
            },
            callingCreateService: {
                invoke: {
                    src: "createService",
                    onDone: {
                        target: 'done',
                    },
                },
            },
            done: {
                type: "final"
            }
        }
    }, {
        guards: {
            objectNeedsGeneratedIdValues: (context) => context?.gidAttributesList?.length > 0
        },
        actions: {
            setObjectListInContext: assign({
                object: (context, event) => {
                    return event?.data?.newObjectList || context.objectList;
                }
            }),
            sendToParentTransientObjectWithGeneratedId: sendParent((context, event) => ({ type: "GENERATED_TRANSIENT_OBJECT_LIST_UPDATED", transientObjectList: event?.data?.newObjectList ?? context.objectList, fieldBeingUpdated: event.generatedIdObject }))
        },
        ...createInBatchMachineOptions
    });
};
