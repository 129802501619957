const token = localStorage.getItem('token');

export const headers = {
    'Authorization': token,
    'content-type': 'application/json'
};

export const headersFormData = {
    'Authorization': token,
    'content-type': 'multipart/form-data'
};


// updates the token after refreshed
export const updateToken = (newToken) => {
    headers.Authorization = newToken;
    headersFormData.Authorization = newToken;
};
