import React from "react";
import ErrorBoundryComponent from "@app/common/error-boundry-component";

export const DashboardModule = ({ children, className = "" }) => {
    return (
        <ErrorBoundryComponent>
            <section className={`bg-white rounded-lg flex flex-col overflow-hidden ${className}`} >
                { children }
            </section>
        </ErrorBoundryComponent>
    );
};

DashboardModule.Header = function ({ children, className = "" }) {
    return <header className={`p-6 border-b border-gray-100 flex items-center ${className}`}>{ children }</header>;
};

DashboardModule.Title = function ({ children }) {
    return <h1 className="text-4xl text-teal font-light w-full mb-0">{ children }</h1>;
};

DashboardModule.Body = function ({ children, className }) {
    return <div className={`p-4 flex-1 overflow-auto ${className}`}>{ children }</div>;
};
