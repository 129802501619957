import { useCallback } from "react";
import { generateConfigDataActorSelector } from "@app/data/config/selector";
import { useActor, useSelector } from "@xstate/react";
import { evaluatedDataSelector } from "@app/data/machine/selectors";
import { isEqual } from "lodash";
import { useConfigData } from "@app/data/config/context";


export const useConfigDataActor = ({ config }) => {
    const { configDataMachineService } = useConfigData();
    const dataActorSelector = useCallback(() => generateConfigDataActorSelector({ config }), [config]);
    const dataActorObj = useSelector(configDataMachineService, dataActorSelector());

    const [dataActorState, dataActorSend] = useActor(dataActorObj.ref);
    const dataActorData = useSelector(dataActorObj.ref, evaluatedDataSelector, isEqual);
    return {
        dataActorObj,
        dataActorState,
        dataActorSend,
        dataActorData
    };
};
