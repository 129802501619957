import request from './request';
import { baseConfig } from '@app/config/baseConfig';

export const apiPrefix = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}`;


export const SequencesService = {
    data: {
        generatedIdService: async ({ attribute }) => {
            if (!attribute || !attribute.schemaId) {
                throw new Error("There is no attribute to generate an ID for");
            }
            const response = await request({
                method: "GET",
                url: `${apiPrefix}/sequences/${attribute.schemaId}`
            });
            if (response.data.status === "OK") {
                return response.data.sequence_number;
            }
            throw new Error("An error occurred while calling Sequences API");
        }
    }
};
