import React from "react";

export const DashboardModuleAlertTileList = ({ children, className }) => {
    return (
        <section className={`flat-alerts-area ${className}`}>
            <div className="mb-2 flex justify-between flex-col gap-3">
                {children}
            </div>
        </section>
    );
};
