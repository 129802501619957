import React, { useEffect } from 'react';
import { redirectToAuth, signOut } from "supertokens-auth-react/recipe/emailpassword";
import { Spin } from 'antd';
import { SimpleSkeleton } from '@app/auth/login/LoadingArea';

/**
 * Allows a routing url to logout and then redirects if appropriate
 * @returns {JSX.Element}
 */
const Logout = ({ performSignout = true, className = undefined }) => {
    const signout = async () => {
        await signOut();
        localStorage.clear();
        redirectToAuth();
    };

    useEffect(() => {
        performSignout && signout();
    }, []);
    return (
        <>
            <div className={`flex justify-center h-screen ${className}`}>
                <div className='m-auto'>
                    <div className='flex flex-col items-center'>
                        <Spin />
                        <div className='p-3 sr-only'>
                            <h2 className='text-3xl'>Logging out...</h2>
                        </div>
                        <SimpleSkeleton className='logout-info w-full' />
                    </div>
                </div>
            </div>
        </>
    );
};


export default Logout;
