/**
 * A machine for loading, updating, and creating attributes
 * @typedef SweftDataMachineAttributeSchemaDataActorMachine
 * @type {StateMachine<SweftDataMachineAttributeSchemaDataActorMachineContext,SweftOptimisticDataMachineEvent>}
 */

/**
 * @interface SweftDataMachineAttributeSchemaDataActorMachineContext
 * @extends {SweftOptimisticDataMachineContext}
 * @prop {"schemaId"} keyProperty
 */

import { VanguardService } from "@app/services/vanguardService";
import { defaultTransientConfig, generateOptimisticDataMachine } from "@app/data/machine/actors/optimisticDataActorMachine";


const attributeSchemaMachineGeneratorProps = {
    type: "attributeSchema",
    keyProperty: "schemaId",
    updateMachineOptions: {
        services: {
            updateService: async (context) => {
                const { object, fieldBeingUpdated } = context;
                return new Promise((resolve) => {
                    VanguardService.schema.updateAttribute({ attribute: object, fieldBeingUpdated }).then(() => {
                        resolve({
                            newObject: object
                        });
                    });
                });
            }
        }
    },
    createMachineOptions: {
        services: {
            createService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    const { entity, name } = object;
                    VanguardService.schema.createAttribute(object)
                        .then(() => {
                            resolve({
                                newObject: {
                                    ...object,
                                    schemaId: `${entity}.${name}`
                                }
                            });
                        });
                });
            },
        }
    },
    services: {
        loadService: async () => {
            return new Promise((resolve) => {
                VanguardService.schema.getAttributes().then((newAttributes) => {
                    resolve({
                        loadedData: newAttributes.response
                    });
                });
            });
        },
        loadDataFilteredForTransientData: async () => {
            return new Promise((resolve) => {
                VanguardService.schema.getAttributes().then((newAttributes) => {
                    resolve({
                        loadedData: newAttributes.response
                    });
                });
            });
        },
    },
    transientConfig: {
        ...defaultTransientConfig,
        evaluateTransientObject: false
    }
};

/**
 * @type {SweftDataMachineAttributeSchemaDataActorMachine}
 */
export const attributeSchemaDataActorMachine = generateOptimisticDataMachine(attributeSchemaMachineGeneratorProps);
