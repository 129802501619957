/**
 * Machine used for spawning the unknownDataActor.
 * The unknownDataActor should be used when a data actor can not be found.
 * Using the unknownDataActor allows for the code that expects a reference to a spawned actor to run
 * While providing an opportunity to check if the found data actor is the unknownDataActor and react accordingly.
 *
 * For example, if the unknownDataActor is found while looking for a data actor of an entity,
 * A command can be sent to the data machine to spawn an entity data actor for the relevant entity.
 *
 * @typedef SweftDataUnknownDataActorMachine
 * @type {StateMachine<SweftDataUnknownDataActorMachineContext, none,SweftDataUnknownDataActorMachineTypestate>}
 */

/**
 * @interface SweftDataUnknownDataActorMachineContext
 * @prop {[]} data
 */

/**
 * @typedef SweftDataUnknownDataActorMachineTypestate
 * @type {
 *  {
 *   value: "idle",
 *   context: SweftDataUnknownDataActorMachineContext
 *  }
 * }
 */

import { createMachine } from "xstate";

/**
 * @type SweftDataUnknownDataActorMachine
 */
export const unknownDataActorMachine = createMachine({
    id: "unknownDataActor",
    context: {
        data: []
    },
    initial: "idle",
    states: {
        idle: {}
    }
});
