import React, { useEffect } from "react";
import { createContext, useContext, useMemo } from "react";
import { useInterpret } from "@xstate/react";
import { useAuth } from "@app/auth/context/useAuth";
import { configDataMachine } from "@app/data/config/machine";

export const ConfigDataContext = createContext({});

export const useConfigData = () => useContext(ConfigDataContext);

export const ConfigDataWrapper = ({ children }) => {
    const { user } = useAuth();
    const configDataMachineService = useInterpret(configDataMachine, { devTools: true });
    const { send: sendToConfigDataMachineService } = configDataMachineService;
    useEffect(() => {
        if (user) {
            sendToConfigDataMachineService({ type: "NEW_USER_OBJECT", user });
        }
    }, [user]);
    const contextValue = useMemo(() => ({
        configDataMachineService,
        sendToConfigDataMachineService
    }), [configDataMachineService, sendToConfigDataMachineService]);


    return (
        <ConfigDataContext.Provider value={contextValue}>{children}</ConfigDataContext.Provider>
    );
};
