import useSessionStorageState from "use-session-storage-state";
import { singletonHook } from 'react-singleton-hook';
import { useCallback } from "react";

export const toggleSpecialtyCallback = ({ specialty, specialtySetter }) => {
    if (specialty.isAvailable) {
        specialtySetter({ ...specialty, isEnabled: !specialty?.isEnabled });
    }
};

/**
 * A singleton custom hook for interfacing with the specialty object throughout Sweft.
 * As a singleton hook, all invocations of this hook share the same state.
 * The state of the specialty is stored in session storage, and it is synchronized accordingly.
 * This provides access to the specialty value via the use of this hook or directly through session storage.
 * @type {() => {}}
 */
export const useSpecialty = singletonHook({}, () => {
    const specialtyString = sessionStorage.getItem("specialty");
    const isSpecialtySet = JSON.parse(specialtyString);
    const defaultSpecialtyValue = isSpecialtySet ? isSpecialtySet : { isAvailable: false, isEnabled: false, config: null, primaryEntity: null };
    const [specialty, setSpecialty] = useSessionStorageState('specialty', {
        defaultValue: defaultSpecialtyValue
    });

    const toggleSpecialty = useCallback(() => toggleSpecialtyCallback({ specialty, specialtySetter: setSpecialty }), [specialty, setSpecialty]);

    return {
        specialty,
        setSpecialty,
        toggleSpecialty
    };
});

