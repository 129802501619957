// @ts-check
import React from 'react';
import { Help } from '@app/common/help/help';
import RolesDropdown, { saveRoleChange } from '@app/common/components/rolesDropdown';
import useSystemRoles from '@app/auth/util/useSystemRoles';
import { useAuth } from '@app/auth/context/useAuth';
import { Button, Modal } from 'antd';
import AuthService from '@app/services/authService';
import { useNavigate } from "react-router-dom";

/**
 * Displaying the different modals when actions occur on the left side
 * @prop {()=>void} modalClose
 * @prop {'help'|'profile'|'requestResetPassword'} modalName
 * @prop {User} user
 * @prop {bool} isOpen
 *
 * @returns
 */
const LeftNavModalContainer = ({ user, modalName, modalClose, isOpen, children, ...rest }) => {
    /** @typedef {{roleId: string, displayName:string, enabled:boolean}} systemRole*/
    /** @type {systemRole[]} rolesManip */
    const systemRoles = useSystemRoles();
    const { refreshUser } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);


    let modalContent = <></>;
    let modalTitle = '';
    if (modalName == 'help') {
        modalContent = <Help/>;
        modalTitle = 'Help';
    } else if (modalName == 'requestResetPassword') {
        const handleOk = (e) => {
            setLoading(true);

            AuthService.requestPasswordReset(user?.email).then((data) => {
                if (data.status) {
                    Modal.success({
                        title: 'Password Reset Requested',
                        content: 'A password reset link has been sent to your email address.',
                    });
                    setLoading(false);
                    modalClose();
                } else {
                    console.error(data.data.responseMessage);
                    Modal.error({
                        title: 'Password Reset Request Failed',
                        content: 'Some error occurred while requesting password reset. Please try again later.',
                    });
                    setLoading(false);
                    modalClose();
                }
            }).catch((error) => {
                console.error(error);
                Modal.error({
                    title: 'Password Reset Request Failed',
                    content: 'Some error occurred while requesting password reset. Please try again later.',
                });
                setLoading(false);
            });
        };
        modalContent = <section>
            <header>
                <h1>Request a password reset email?</h1>
            </header>
            <p>You will receive an email sent to <strong>{user?.email}</strong> with a link to follow to update your password.</p>
            <footer>
                <Button className="mr-5" type="default" disabled={loading} loading={loading} onClick={modalClose}>Cancel</Button>
                <Button type="primary" disabled={loading} loading={loading} onClick={handleOk}>Request</Button>
            </footer>
        </section>;
        modalTitle = 'Reset Password';
    } else if (modalName == 'profile') {
        modalContent = <RolesDropdown
            {...rest}
            systemRoles={systemRoles}
            onClose={modalClose}
            user={user}
            saveRoleChange={saveRoleChange}
            refreshUser={refreshUser}
            navigate={navigate}
        />;
        modalTitle = 'Profile';
    }

    return (
        <Modal title={modalTitle} open={isOpen} footer={null} onCancel={modalClose}>
            {modalContent}
        </Modal>
    );
};

export default LeftNavModalContainer;
