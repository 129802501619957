import React from 'react';

/**
 * Login footer with build version
 * @param {*} param0
 * @todo Create a story for this
 * @returns {JSX.Element}
 */
export const LoginFooter = ({ children, buildVersion, className }) => {
    return <div className={`login-footer ${className ?? ''}`} >
        {children}
        <footer role="footer">
            <div className="text-center copyright-content">
                <small>Copyright &copy; {new Date().getFullYear()} SWEFT. All rights reserved.</small>
            </div>

            <div className="text-center build-version">
                <small>{buildVersion}</small>
            </div>
        </footer>
    </div>;
};
