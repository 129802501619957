// @ts-check
import request from './request';
import { baseConfig } from '@app/config/baseConfig';

export const api = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/users`;
export const logoApi = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/asset-mgmt/client-logo`;
import { roleChecks } from "@app/auth/roleChecks";

/**
 * @param {Partial<User>} possibleUser The possible user data to create or update
 * @param {User} selfUser The person making the change request
 * @returns {User}
 * @throws {Error} if the user is invalid
 */
export const validateUser = (possibleUser, selfUser) => {
    if (!possibleUser?.userId) {
        throw new Error('No user id on the user to save');
    }
    if (!possibleUser?.email) {
        throw new Error('Cannot save a user without an email address');
    }
    if (!possibleUser?.userName) {
        throw new Error('Cannot save a user without a username');
    }
    if (![true, false].includes(possibleUser?.status)) {
        throw new Error(`Cannot save a user with as status of ${JSON.stringify(possibleUser?.status)}, only true or false`);
    }
    if (!possibleUser?.primaryRole) {
        throw new Error('No user primaryRole set to save');
    }
    if (!Array.isArray(possibleUser?.roles)) {
        throw new Error('User roles incorrect format');
    }
    // Validate that each role is in the string format
    if (possibleUser?.roles?.length > 0 && !possibleUser?.roles.every(rol => typeof rol === "string")) {
        throw new Error('Unable to save a user with invalid individual roles format');
    }
    if (possibleUser?.roles?.length > 0 && !(possibleUser?.roles?.includes(possibleUser?.primaryRole))) {
        throw new Error('Cannot set a user with a primary role that they don\'t have in their list');
    }
    // Validate that current user must be super admin to change a superadmin
    const nonSuperSelf = selfUser?.roles?.filter(rol => roleChecks.isRoleSuperAdmin(rol?.roleId))?.length < 1;
    if (nonSuperSelf &&
        (roleChecks.isRoleSuperAdmin(possibleUser?.primaryRole) || possibleUser?.roles?.filter(rol => roleChecks.isRoleSuperAdmin(rol?.roleId))?.length > 0)
    ) {
        throw new Error('Unable to change roles in this way from your current role');
    }
    return possibleUser;
};

/**
 * @param {User} user
 * @returns {Record<string, string | number | string[]>}
 * @private do not export, internal implementation detail
 */
const parseUserForSave = (user) => {
    const constrainCurrentRole = (current, primary, list) => {
        if (!list?.includes(current)) {
            return primary || list?.[0];
        }
        return current;
    };

    return {
        ...user,
        roles: JSON.stringify(user.roles),
        status: JSON.stringify(user.status),
        currentRole: constrainCurrentRole(user?.currentRole, user?.primaryRole, user?.roles)
    };
};

const UserService = {
    /**
     * Check status of the service
     * @returns {Promise<unknown>}
     */
    status: async () => {
        return request({
            method: "GET",
            url: `${api}/status`
        });
    },


    /**
     *
     * @param {CredentialedUser} user
     * @param {CredentialedUser} selfUser
     * @returns {Promise<{status: string}>}
     * @throws {Error} On invalid user format to save
     */
    upsertUser: (user, selfUser) => {
        validateUser(user, selfUser); // This throws out errors that it is the responsibility of callers to catch and handle

        // Validation should pre-enforce that this is an array of strings now

        return request({
            method: "PUT",
            url: `${api}/${user.userId}`,
            data: parseUserForSave(user),
        }).then((response) => {
            return response.data;
        });
    },

    /**
     * Update just the current role of the user
     * @throws {Error}
     */
    updateCurrentRole: (userId, newCurrentRole) => {
        if (!userId) {
            throw new Error("User Update Error 9748: UserId is required");
        }
        if (!newCurrentRole) {
            throw new Error("User Update Error 8325: New current role is required");
        }
        return request({
            method: "PATCH",
            url: `${api}/current-role/${userId}/${newCurrentRole}`,
        }).then((response) => {
            return response.data;
        });
    },


    /**
     * Get an arbitrary user
     * @param {string} userId
     * @returns {Promise<{data: User}>}
     * @throws {Error}
     */
    getUser: (userId) => {
        return request({
            method: "GET",
            url: `${api}/${userId}`
        }).then((response) => {
            return response.data;
        });
    },
    /**
     * Declaratively set the status of a user
     * @param {User} selfUser
     * @param {User} changingUser
     * @param {boolean} status
     * @returns {Promise<{status: string}>}
     * @throws {Error} On invalid user format to save
     */
    updateUserStatus: (selfUser, changingUser, status) => {
        console.info('Updating user status', changingUser, status);
        // Uses full user update for now
        if (!changingUser?.userId) {
            throw new Error("User Status Update Error 9554: UserId is required");
        }
        if (status !== true && status !== false) {
            throw new Error("User Status Update Error 9325: Status must be true or false");
        }
        validateUser(changingUser, selfUser);
        return request({
            method: "PUT",
            url: `${api}/${changingUser.userId}`,
            data: parseUserForSave(changingUser),
        }).then((response) => {
            return response.data;
        });
    },

    /**
     *
     * @throws {Error}
     */
    listUsers: () => {
        return request({
            method: "GET",
            url: `${api}`
        }).then((response) => {
            return response.data;
        });
    },

    /**
     *
     * @throws {Error}
     */
    listViewableUsers: (currentUser) => {
        return request({
            method: "GET",
            url: `${api}`
        }).then((response) => {
            return response.data?.data?.filter((lRow) => {
                if (roleChecks.isRoleSuperAdmin(currentUser?.primaryRole)) {
                    return true;
                } else {
                    // filter out superadmins
                    return !roleChecks.isRoleSuperAdmin(lRow?.primaryRole);
                }
            });
        });
    },

    /**
     *
     * @throws {Error}
     */
    deleteUser: (userId) => {
        return request({
            method: "DELETE",
            url: `${api}/${userId}`
        }).then((response) => {
            return response.data;
        });
    },

    /**
     *
     * @throws {Error}
     */
    fetchClientLogoURL: () => {
        return request({
            method: "POST",
            url: `${logoApi}`
        }).then((response) => {
            return response;
        });
    },


    /**
     * Request a welcome invite email for a person
     * @param {string} email
     */
    requestWelcomeInvite: async (email) => {
        // validate email format
        if (!email || !email.includes('@')) {
            throw new Error('Invalid email format');
        }
        try {
            return await request({
                method: 'POST',
                data: {
                    "send-welcome": true,
                    "user-identity": {
                        "email": email,
                    }
                },
                url: `${api}/user/welcome-invite`
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
};

export default UserService;
