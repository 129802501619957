/* globals SERVICE_URL, SWEFT_GATEWAY_API */
// @ts-check

// Set up a cascade of defaults, falling back to current url
const baseHostname = `${window.location.hostname}`.replace(/\/$/, ""); // Removes trailing slash
// Service URL is the location to find the Java API
// @ts-ignore
const serviceUrl = SERVICE_URL || `https://api.${baseHostname}`;
// SWEFT_GATEWAY_API is the base hostname of the microservices in aws, e.g. integration or a dev sandbox or tenant
// @ts-ignore
const defaultGatewayApi = SWEFT_GATEWAY_API || SERVICE_URL || `https://api.${baseHostname}`; // override with env var
let gatewayApiBase;
let gatewayApiBaseProtocol;
// Get the domain with port of the current frontend domain
const baseDomain = `${window.location.origin}`.replace(/\/$/, ""); // Remove trailing slash

/**
 * @type {Object}
 * @property {string} [SERVICE_API] e.g. 'https://api.integration.makesweft.com' or even 'http://localhost:1234'
 * @property {string} [GATEWAY_API] e.g. 'https://api.integration.makesweft.com'
 * @property {string} [GATEWAY_API_BASE] e.g. integration.makesweft.com
 * @property {string} [GATEWAY_API_BASE_PROTOCOL] e.g. https: or http:
 * @property {string} [AUTH_API_DOMAIN] e.g. https://api.integration.makesweft.com or sandbox url
 * @property {string} [AUTH_API_BASE_PATH] e.g. /auth/auth
 * @property {string} [AUTH_WEBSITE_BASE_PATH] e.g. /auth
 * @property {string} [AUTH_APP_NAME] e.g. 'sweft'
 */
export const baseConfig = {
    WEBSITE_DOMAIN: baseDomain, // e.g. http://localhost:9000 or integration
    SERVICE_API: serviceUrl, // e.g. localhost:1234 for dev
    GATEWAY_API: defaultGatewayApi, // e.g. an aws sandbox api for dev
    GATEWAY_API_BASE: gatewayApiBase = ((new URL(defaultGatewayApi)).hostname).replace(/\/$/, "").replace(/api\./, ""),
    GATEWAY_API_BASE_PROTOCOL: gatewayApiBaseProtocol = (new URL(defaultGatewayApi).protocol), // http: or https:
    AUTH_API_DOMAIN: `${gatewayApiBaseProtocol}//api.${gatewayApiBase}`,
    AUTH_API_BASE_PATH: "/auth/auth",
    AUTH_WEBSITE_BASE_PATH: "/auth",
    AUTH_APP_NAME: "sweft",
};

if (!baseConfig?.GATEWAY_API_BASE) {
    throw new Error("Sweft Error: API Base Not Configured");
}

// If you want to changes any one of these that is not dynamic,
// define an environment variable in the webpack config with a fallback

// BUILD_VER is not added to this config
