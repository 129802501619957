import React from "react";
import { LoadingSpinner } from "./loadingSpinner";

const SuspenseFallbackLoader = () => {
    return <div className='flex justify-center w-full h-full'>
        <LoadingSpinner />
    </div>;
};

export default SuspenseFallbackLoader;
