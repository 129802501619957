import React from 'react';
import './drawer.less';
import { useLayout } from "@app/layout/contexts/useLayout";

const Drawer = ({ reverse = false, ...props }) => {
    const { drawerOpen, setDrawerOpen, rightDrawerOpen, setRightDrawerOpen } = useLayout();

    const toggleDrawer = () => {
        if (reverse === false) {
            setDrawerOpen(!drawerOpen);
        } else {
            setRightDrawerOpen(!rightDrawerOpen);
        }
    };

    return (
        <div className={`layout-drawer ${(reverse) ? 'reverse' : ''} ${((reverse === false && drawerOpen) || (reverse === true && rightDrawerOpen)) ? 'layout-drawer-open' : ''}`}>
            <div className="layout-drawer-header">
                <div className="layout-drawer-title">
                    {props.title}
                    <span className="close-btn" onClick={toggleDrawer}>
                        <i className="icon-close" />
                    </span>
                </div>
                <div className="layout-drawer-content">
                    {props.children}
                </div>
            </div>
            <div className="layout-drawer-puller" onClick={toggleDrawer}>
                <i className="layout-drawer-arrow" />
            </div>
        </div>
    );
};

export default Drawer;
