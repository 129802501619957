
/**
 * Allows for checking against data or role string
 * without instantiating a user object
 */
export const roleChecks = {
    /**
     * @param {string} roleIdentity
     * @returns {boolean}
     */
    isRoleSuperAdmin: (roleIdentity) => {
        return roleIdentity === "SUPERADMIN";
    },
    isRoleAdmin: (roleIdentity) => {
        return roleIdentity === "Admin";
    }
};

/**
 * To prevent any hardcoding of superadmin, provide the form structure
 * analogous to the database data
 */
export const superAdminStructure = {
    label: "SuperAdmin", value: "SUPERADMIN"
};
