import { useMemo } from "react";
import { useFeature } from "flagged";
import { applyAdvancedFilters } from "@app/grid/advancedFilters/advancedFilters";
import { useSpecialty } from "@app/specialty/hooks/useSpecialty";
import { findWorkspaceSpecialty } from "@app/specialty/utils/findWorkspaceSpecialty";
import { useDataActor } from "@app/data/useDataActor";
import { useAlertDataActorOptions } from "@app/contexts/useAlerts/useAlertDataActorOptions";
import { useAlerts } from "@app/contexts/useAlerts/context";

const useLegacyAlertStatus = ({ alert, withPreFilter = false }) => {
    const { alertWorkspaceObj } = alert;
    const { specialty } = useSpecialty();
    const dataActorOptions = useAlertDataActorOptions({ alert, withPreFilter });

    const {
        dataActorState: alertDataActorState,
        dataActorData: alertEntityData
    } = useDataActor({ dataActorOptions, spawnSource: "useLegacyAlertStatus" });

    const matchingObjectList = useMemo(() => {
        let objListForAlert = alertEntityData;
        if (specialty.isEnabled) {
            const workspaceSpecialty = findWorkspaceSpecialty({ specialty, workspace: alertWorkspaceObj });
            if (workspaceSpecialty) {
                objListForAlert = applyAdvancedFilters({ logic: workspaceSpecialty?.savedQueryObject, rows: objListForAlert });
            }
        }
        return objListForAlert;
    }, [alertEntityData, alertWorkspaceObj, specialty]);

    const isLoading = !alertDataActorState?.matches("loader.idle");
    const isFailed = alertDataActorState?.context?.loadingDataError !== null;
    return {
        isLoading,
        isFailed,
        savedQueryCount: matchingObjectList.length
    };
};

export const useAlertSavedQueryStatus = ({ alert }) => {
    const { entity, savedQueryId, additionalJsonLogicQueryObject } = alert;
    const { specialtyList } = useAlerts();
    const { specialty } = useSpecialty();
    const dataActorOptions = useMemo(() => {
        const options = {
            dataActorType: "entitySavedQueryCounts",
            entity,
            savedQueryId,
            additionalJsonLogicQueryObject
        };
        if (specialty.isEnabled) {
            const workspaceSpecialtyAlertObj = specialty?.config.reduce((foundAlertObj, nextAlertId) => {
                if (foundAlertObj) {
                    return foundAlertObj;
                }
                const specialtyObj = specialtyList?.find(({ alertId: _alertId }) => nextAlertId === _alertId);

                if (specialtyObj?.workspace === alert?.workspace){
                    return specialtyObj;
                }

                return null;
            }, null);
            if (workspaceSpecialtyAlertObj?.savedQueryId) {
                options.additionalSavedQueryIdList = [workspaceSpecialtyAlertObj?.savedQueryId];
            } else {
                options.additionalSavedQueryIdList = [];
            }
        }
        return options;
    }, [additionalJsonLogicQueryObject, entity, savedQueryId, specialty.config, specialty.isEnabled, specialtyList]);
    const {
        dataActorState,
    } = useDataActor({ dataActorOptions, spawnSource: "useAlertSavedQueryStatus", noSpawning: true });
    const isLoading = !dataActorState.matches("waitingBeforeNextFetch");
    const { savedQueryCountsByIdMap } = dataActorState?.context ?? {};
    const savedQueryCount = savedQueryCountsByIdMap?.[savedQueryId];
    return {
        isLoading,
        savedQueryCount,
        savedQueryId
    };
};

export const useAlertStatus = ({ alert, withPreFilter = false }) => {
    const shouldUseSavedQueryAlertsModule = useFeature('savedQueryAlerts');
    if (shouldUseSavedQueryAlertsModule) {
        return useAlertSavedQueryStatus({ alert });
    }
    return useLegacyAlertStatus({ alert, withPreFilter });
};
