// @ts-check
import { useAuth } from "@app/auth/context/useAuth";
import React, { createContext, useCallback, useContext, useMemo, useRef } from "react";
import { createEntityToRelatedAttributeMaps, getVisibleColumnListForSuperAdmin } from "@app/contexts/useEntitlements/utils";
import { useConfigData } from "@app/data/config/context";
import { useSelector } from "@xstate/react";
import { isEqual } from "lodash";
import { LoadingSpinner } from "@app/common/loadingSpinner";

/* eslint max-lines-per-function: "Warn" */
/* eslint max-lines: "Warn" */


export const getAllEntitiesRelatedToColumns = (columnList) => {
    const entities = columnList.filter(col => col.relation).map(col => col.relation);
    const entitiesSet = new Set(entities);
    return Array.from(entitiesSet);
};

/**
 * Filters the viewConfigList for views that are on AND
 * have been configured for the relevant role
 * OR namespaced by the userId
 * @prop {Array<SweftViewConfig>} viewConfigList
 * @prop {Partial<SweftRoleConfig>} roleConfig
 * @prop {User | {}} user
 * @returns {Array<SweftViewConfig>} - filtered viewConfigList
 */
export const buildEntitledViewList = ({ viewConfigList = [], roleConfig = {}, user = {} }) => {
    if (["SUPERADMIN", "Admin"].includes(user?.currentRole)) {
        return viewConfigList;
    }
    return viewConfigList.filter((view) => {
        if (view.state !== 'On') {
            return false;
        }
        if (roleConfig?.viewList?.includes(view?.configurationId)) {
            return true;
        }
        // @ts-ignore
        return view?.namespace === user?.userId?.toString();
    });
};

/**
 * Use for the user to know their expected entitlements, including workspaces and roles
 * @prop {User} user
 * @prop {Entitlement} currentEntitlement
 * @prop {Array<SweftWorkspaceConfig>} workspaceConfigList
 * @prop {Array<SweftRoleConfig>} roleConfigList
 * @prop {Array<SweftAttributeSchema>} columnConfigList
 * @returns {Entitlement}
 */
export const buildEntitlement = ({
    user = {},
    workspaceConfigList = [],
    roleConfigList = [],
    columnConfigList = [],
}) => {
    let newEntitlements;
    const { currentRole = '' } = user ?? {};
    if (!currentRole) {
        console.info('No current role set');
    }

    const relatedEntities = getAllEntitiesRelatedToColumns(columnConfigList);
    switch (currentRole) {
        case 'SUPERADMIN':
            newEntitlements = {
                workspaceList: workspaceConfigList.filter((workspace) => {
                    return workspace.displayName !== 'Dashboard';
                }),
                enabledWorkspaceList: workspaceConfigList,

                roleList: roleConfigList,
                columnList: getVisibleColumnListForSuperAdmin({ columnList: columnConfigList }),
            };
            break;
        case 'Admin':
            // Admin provides access to extra workspaces (generally excluding superadmin)
            newEntitlements = {
                workspaceList:
                    workspaceConfigList.filter((workspace) => {
                        return workspace.state === 'On' &&
                            workspace.displayName !== 'Dashboard' &&
                            workspace.displayName !== 'Admin';
                    }),

                enabledWorkspaceList:
                    workspaceConfigList.filter((workspace) => {
                        return workspace.state === 'On' ||
                            workspace.displayName === 'Dashboard';
                    }),

                roleList:
                    roleConfigList.filter((role) => {
                        return role.state === 'On';
                    }),

                columnList:
                    columnConfigList.filter((col) => {
                        return col.state === 'On' || relatedEntities.includes(col.entity);
                    }),
            };
            break;
        default:
            // Non-special roles get custom entitlements
            // For roles, we just return the list of role configs that a user currently has access to
            const flattenedSelfRoles = user?.roles?.map((selfRole) => {
                return selfRole.roleId;
            });

            const selfRoleConfigs = roleConfigList.filter((role) => {
                return flattenedSelfRoles?.includes(role.roleId);
            });

            const currentRoleConfig = selfRoleConfigs?.find((role) => {
                return role.roleId === decodeURIComponent(currentRole);
            });


            newEntitlements = {
                workspaceList: workspaceConfigList,
                enabledWorkspaceList:
                    workspaceConfigList.filter((workspace) => {
                        return workspace.state === 'On' &&
                            workspace.displayName !== 'Admin' &&
                            currentRoleConfig?.modules?.includes(workspace.displayName);
                    }),
                roleList: selfRoleConfigs,
                columnList:
                    columnConfigList.filter((col) => {
                        return col.state === 'On' &&
                            currentRoleConfig?.columnList?.hasOwnProperty(
                                `${col.schemaId}`
                            );
                    }),
            };
            break;
    }
    const { entityToRelatedAttributeMap, entitiesToRelatedEntityMap, entitiesFormulaMap } = createEntityToRelatedAttributeMaps({ columnList: columnConfigList });
    newEntitlements.entityToRelatedAttributeMap = entityToRelatedAttributeMap;
    newEntitlements.entitiesMap = entitiesToRelatedEntityMap;
    newEntitlements.entitiesFormulaMap = entitiesFormulaMap;
    return newEntitlements;
};

/**
 * Returns true if the column is either the same as the parameter entity or is in the array of subEntities passed in
 * @prop {SweftAttributeSchema} column
 * @prop {Array<string>}subEntities
 * @prop {string} entity
 * @returns {boolean}
 */
export const filterColumnOfEntityOrSubEntity = ({ column, subEntities, entity }) => {
    return column.entity === entity || subEntities.includes(column.entity);
};

/**
 * Returns true if the column is the same as the parameter entity
 * @returns {boolean}
 */
export const filterColumnOfEntity = ({ column, entity }) => {
    return column.entity === entity;
};

/**
 * Returns true if the column is in the role object's list and not hidden
 * @returns {boolean}
 */
export const filterColumnVisibleToRole = ({ column, userRoleObj }) => {
    return userRoleObj?.columnList[column.schemaId] !== 'HIDE';
};

/**
 * Creates the partial column definition
 * @param {SweftAttributeSchema} column
 * @returns {PartialColumnDefinition}
 */
export const setUpInitialColumnDefinition = (column) => {
    return {
        ...column,
        colId: column.schemaId,
        field: column.schemaId.split('.')[1]
    };
};

/**
 * Generates a column list for the passed in entity that the passed in user role is entitled to seeing.
 * @prop {string} entity
 * @prop {Array<SweftAttributeSchema>} columnList
 * @prop userRoleObj
 * @prop {Array<string>} subEntities
 * @prop {boolean} includeSubEntityColumns
 * @returns {Array<PartialColumnDefinition>}
 */
export const generateEntitledEntityColumnList = ({ entity = "", columnList = [], userRoleObj, subEntities, includeSubEntityColumns = false } = {}) => {
    if (entity === "") {
        return [];
    }

    let generatedColumnList = columnList;
    if (includeSubEntityColumns) {
        generatedColumnList = generatedColumnList.filter((column) => filterColumnOfEntityOrSubEntity({ column, entity, subEntities }));
    } else {
        generatedColumnList = generatedColumnList.filter((column) => filterColumnOfEntity({ column, entity }));
    }
    generatedColumnList = generatedColumnList.filter((column) => filterColumnVisibleToRole({ column, userRoleObj }));

    return generatedColumnList.map(setUpInitialColumnDefinition);
};

/** @typedef {{ roleId: string, displayName: string, state: 'On'|'Off'|null }} RoleConfig */


/**
 * Container for providing the entitlements loading
 * @type {React.Context<EntitlementContextValue>}
 */
export const EntitlementContext = createContext({});

/**
 * Access that a user may be entitled to
 * @returns {EntitlementContextValue}
 */
export const useEntitlements = () => useContext(EntitlementContext);

const entitlementsDataActorObjSelector = (state) => state.context.entitlementsDataActor;

/**
 * Use to provide the entitlements context, which includes permissions, access, and roles
 * one important effect of this is a stored list of workspaces, and access
 */
export const EntitlementsWrapper = ({ children }) => {
    const { user } = useAuth();
    const { configDataMachineService } = useConfigData();
    const entitlementsDataActorObj = useSelector(configDataMachineService, entitlementsDataActorObjSelector, isEqual);

    const entitledEntitiesColumnsListMap = useRef({});
    const entitlement = useSelector(entitlementsDataActorObj.ref, (state) => state.context.entitlement);
    const initialEntitlementsLoadComplete = useSelector(entitlementsDataActorObj.ref, (state) => state.matches("entitled"));

    const getEntityColumnsList = useCallback(({ entity, includeSubEntityColumns = false }) => {
        const listName = includeSubEntityColumns ? `${entity}WithSubEntityColumns` : entity;
        if (entitledEntitiesColumnsListMap.current[listName] && entitledEntitiesColumnsListMap.current[listName].length > 0) {
            return entitledEntitiesColumnsListMap.current[listName];
        }
        const { columnList, roleList, entitiesMap, entityToRelatedAttributeMap } = entitlement;
        const subEntities = entitiesMap?.[entity] || [];
        const relatedAttributesMap = entityToRelatedAttributeMap?.[entity] || [];
        let userRoleObj;
        if (user?.currentRole !== "SUPERADMIN") {
            userRoleObj = roleList.find((role) => role.roleId === decodeURIComponent(user.currentRole));
        } else {
            // Create a temp userRoleObj for SuperAdmins
            // That has an empty column list
            userRoleObj = {
                columnList: {}
            };
        }

        const columnListOfEntity = generateEntitledEntityColumnList({
            entity,
            columnList,
            userRoleObj,
            includeSubEntityColumns,
            subEntities,
            relatedAttributesMap
        });

        entitledEntitiesColumnsListMap.current[listName] = columnListOfEntity;
        return columnListOfEntity;
    }, [entitlement, user, entitledEntitiesColumnsListMap]);

    const getEntityColumn = useCallback(({ entity, attribute }) => {
        if (!entitledEntitiesColumnsListMap.current[entity]) {
            getEntityColumnsList({ entity });
        }
        return entitledEntitiesColumnsListMap.current[entity].find((col) => col.colId === `${entity}.${attribute}`);
    }, [getEntityColumnsList]);

    /**
     * @type {EntitlementContextValue}
     */
    const contextValue = useMemo(() => ({
        ...entitlement,
        getEntityColumnsList,
        getEntityColumn,
    }), [entitlement, getEntityColumn, getEntityColumnsList]);

    if (!initialEntitlementsLoadComplete) {
        return <LoadingSpinner/>;
    }

    return (
        <EntitlementContext.Provider value={contextValue}>{children}</EntitlementContext.Provider>
    );
};
