import { findDataActorObjForConfigFromContext } from "@app/data/machine/utils";

export const generateConfigDataActorSelector = ({ config }) => {
    return function (state) {
        if (config) {
            const dataActorObj = findDataActorObjForConfigFromContext({ context: state.context, config });
            if (!!dataActorObj === true) {
                return dataActorObj;
            }
        }

        return state.context.unknownDataActor;
    };
};
