import React, { Fragment } from 'react';
import './header.less';
import { useLayout } from "@app/layout/contexts/useLayout";
import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";

const Header = (props) => {
    const { currentWorkspace } = useWorkspaces();
    const { parentPageTitle, childPageTitle, isFullscreenWorkspace, toggleFullscreenWorkspace } = useLayout();
    if (currentWorkspace?.specifications?.layoutConfig?.hideHeader) { return null; }
    return (
        <header className="sweft-header" role='banner'>
            <div className="header-title">
                <h2>{parentPageTitle}{childPageTitle !== null && <Fragment><span className="child-page-spacer">/</span><span>{childPageTitle}</span></Fragment>}</h2>
            </div>
            <ul>
                {props.children}
                <li className="expand-screen-parent">
                    <button className="expand-screen-button" onClick={toggleFullscreenWorkspace}>
                        <i className={isFullscreenWorkspace ? 'icon-collapse' : 'icon-expand'} />
                    </button>
                </li>
            </ul>
        </header>
    );
};

export default Header;
