import { assign, createMachine } from "xstate";
import { buildEntitlement } from "@app/contexts/useEntitlements";

export const entitlementsDataActorMachine = createMachine({
    id: "entitlements",
    initial: "waiting",
    context: {

    },
    states: {
        waiting: {

        },
        checkingIfReadyToBuildEntitlement: {
            always: [{
                cond: "readyToBuildEntitlement",
                target: "buildingEntitlement"
            },
            {
                target: "waiting"
            }]
        },
        buildingEntitlement: {
            invoke: {
                src: "entitlementBuilder",
                onDone: {
                    actions: "setEntitlement",
                    target: "entitled"
                },
            }
        },
        entitled: {

        }
    },
    on: {
        CONFIG_LOADED: {
            actions: "setLoadedConfig",
            target: "checkingIfReadyToBuildEntitlement"
        },
        NEW_USER_OBJECT: {
            actions: "setUser",
            target: "checkingIfReadyToBuildEntitlement"
        }
    }
},
{
    guards: {
        readyToBuildEntitlement: (context) => {
            const { rolesConfig, workspacesConfig, attributeSchema, user } = context;
            const configListLoaded = Array.isArray(rolesConfig) && Array.isArray(workspacesConfig) && Array.isArray(attributeSchema);
            return configListLoaded && user;
        }
    },
    actions: {
        setLoadedConfig: assign((context, event) => {
            const { loadedConfigName, loadedConfigValue } = event;
            return {
                [loadedConfigName]: loadedConfigValue
            };
        }),
        setEntitlement: assign({
            entitlement: (context, event) => event.data.entitlement
        }),
        setUser: assign({
            user: (context, event) => event.user
        })
    },
    services: {
        entitlementBuilder: async(context) => {
            const { rolesConfig, workspacesConfig, attributeSchema, user } = context;
            const entitlement = buildEntitlement({
                user: user,
                workspaceConfigList: workspacesConfig,
                roleConfigList: rolesConfig,
                columnConfigList: attributeSchema,
            });
            return { entitlement };
        }
    }
});
