import React from "react";

const AnimatedPing = () => <div className="flex h-5 w-5">
    <div className="relative">
        <span
            className="animate-ping absolute inline-flex h-full w-full rounded-full bg-teal-400 opacity-75"
        />
        <span className="relative inline-flex rounded-full h-5 w-5 bg-teal-500 bg-opacity-20"/>
    </div>
</div>;

export default React.memo(AnimatedPing);
