import request from './request';
import { v4 as uuidv4, v5 as uuidV5 } from 'uuid';
import { baseConfig } from '@app/config/baseConfig';

const ns = '1ce26b9a-1670-4f4d-b4b3-170a2d72b828';

const configurationApi = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/configuration`;

const ConfigurationService = {
    /**
     * Check status of the configuration service
     * Gives a 401 when no session is available
     * @returns {Promise<unknown>}
     */
    status: async () => {
        return request({
            method: "GET",
            url: `${configurationApi}/status`
        });
    },
    generateViewUuid(displayName, namespace, entity) {
        return uuidV5(`${displayName}_${namespace}_${entity}`, ns);
    },
    generateConfigFromAttributes: (attr) => {
        return {
            configurationId: uuidV5(attr.systemId, ns),
            configurationType: 'SYSTEM',
            entity: attr.entity,
            systemId: attr.systemId,
            baseType: attr.type,
            displayName: attr.displayName,
            format: 'Default',
            specifications: {},
            validations: {}
        };
    },
    getColumnConfigList: async () => {
        const response = await request({
            method: "GET",
            url: `${configurationApi}/columns`
        });
        return response.data.data;
    },
    updateColumnConfig: async (config) => {
        const response = await request({
            method: "PATCH",
            url: `${configurationApi}/columns/${config.configurationId}`,
            data: config
        });

        return response.data;
    },
    saveColumnConfig: async (config) => {
        if (!config?.configurationId) {
            const newConfigurationId = uuidv4();
            config.configurationId = newConfigurationId;
            config.configurationType = 'CUSTOM';
            config.systemId = `${config.entity}.Custom.${newConfigurationId}`;
        }
        const response = await request({
            method: "PUT",
            url: `${configurationApi}/columns/${config.configurationId}`,
            data: config
        });

        return response.data;
    },
    removeColumnConfig: async (config) => {
        const response = await request({
            method: "DELETE",
            url: `${configurationApi}/columns/${config.configurationId}`
        });
        return response.data;
    },
    /**
     * @returns {Promise<Array<SweftRoleConfig>>}
     */
    getRoleConfigList: async () => {
        const response = await request({
            method: "GET",
            url: `${configurationApi}/roles`
        });
        return response.data.data;
    },

    /**
     *
     * @param {SweftRoleConfig} config
     * @returns {*}
     */
    saveRoleConfig: (config) => {
        if (!config?.configurationId) {
            config.configurationId = uuidv4();
        }
        return request({
            method: "PUT",
            url: `${configurationApi}/roles/${config.configurationId}`,
            data: config
        }).then((response) => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    removeRoleConfig: (config) => {
        return request({
            method: "DELETE",
            url: `${configurationApi}/roles/${config.configurationId}`,
        }).then((response) => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    /**
     *
     * @returns {Promise<Array<SweftWorkspaceConfig>>}
     */
    getWorkspaceConfigList: async () => {
        const response = await request({
            method: "GET",
            url: `${configurationApi}/workspaces`
        });

        return response.data.data;
    },
    saveWorkspaceConfig: async (config) => {
        if (!config?.configurationId) {
            config.configurationId = uuidv4();
        }
        const response = await request({
            method: "PUT",
            url: `${configurationApi}/workspaces/${config.configurationId}`,
            data: config
        });

        return response.data;
    },
    saveSuperAdminWorkspaceConfig: async (config) => {
        if (!config?.configurationId) {
            config.configurationId = uuidv4();
        }
        const response = await request({
            method: "PUT",
            url: `${configurationApi}/workspaces/superadmin/${config.configurationId}`,
            data: config
        });

        return response.data;
    },
    removeWorkspaceConfig: async (config) => {
        const response = await request({
            method: "DELETE",
            url: `${configurationApi}/workspaces/${config.configurationId}`,
        });

        return response.data;
    },
    /**
     * @returns {Promise<SweftViewConfig>}
     */
    getViewConfigList: async () => {
        const response = await request({
            method: "GET",
            url: `${configurationApi}/views`
        });

        return response.data.data;
    },
    saveViewConfig: async (config) => {
        const response = await request({
            method: "PUT",
            url: `${configurationApi}/views/${config.configurationId}`,
            data: config
        });

        return response.data;
    },
    getViewConfig: async (configId) => {
        const response = await request({
            method: "GET",
            url: `${configurationApi}/views/${configId}`
        });
        return response.data;
    },
    removeViewConfig: async (config) => {
        const response = await request({
            method: "DELETE",
            url: `${configurationApi}/views/${config.configurationId}`,
        });

        return response.data;
    },
    /**
     * @deprecated
     */
    getAlertsConfigList: async () => {
        const response = await request({
            method: "GET",
            url: `${configurationApi}/alerts`
        });

        return response.data.data;
    },
    /**
     * @deprecated
     */
    saveAlertConfig: async (config) => {
        if (!config?.configurationId) {
            config.configurationId = uuidv4();
        }
        const response = await request({
            method: "PUT",
            url: `${configurationApi}/alerts/${config.configurationId}`,
            data: config
        });

        return response.data;
    },
    /**
     * @deprecated
     */
    getAlertConfig: async (configId) => {
        const response = await request({
            method: "GET",
            url: `${configurationApi}/alerts/${configId}`
        });
        return response.data;
    },
    /**
     * @deprecated
     */
    removeAlertConfig: async (config) => {
        const response = await request({
            method: "DELETE",
            url: `${configurationApi}/alerts/${config.configurationId}`,
        });

        return response.data;
    },
};

export default ConfigurationService;
