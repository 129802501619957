import { useActor, useSelector } from "@xstate/react";
import { isEqual } from "lodash";
import { evaluatedDataSelector } from "@app/data/machine/selectors";
import { useConfigData } from "@app/data/config/context";

const attributesDataActorObjSelector = (state) => state.context.attributeSchemaDataActor;

export const useAttributesDataActor = () => {
    const { configDataMachineService } = useConfigData();
    const attributesDataActorObj = useSelector(configDataMachineService, attributesDataActorObjSelector, isEqual);
    const [attributesDataActorState, attributesDataActorSend] = useActor(attributesDataActorObj.ref);
    const attributesDataActorData = useSelector(attributesDataActorObj.ref, evaluatedDataSelector, isEqual);
    return {
        attributesDataActorObj,
        attributesDataActorState,
        attributesDataActorSend,
        attributesDataActorData
    };
};
