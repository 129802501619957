import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "@app/auth/context/useAuth";
import MessagesService from "@app/services/messagesService";

const MessagesContext = createContext();

export const useMessages = () => useContext(MessagesContext);

export const MessagesWrapper = ({ children }) => {
    const [messageListForSingleObject, setMessageListForSingleObject] = useState([]);
    const [messageListForUserDashboard, setMessageListForUserDashboard] = useState([]);
    const { user } = useAuth();

    const getMessagesListForSingleObject = async (objectId) => {
        await MessagesService.getMessageByObjectId(objectId).then((res) => {
            const sortMessages = sortMessagesList(res?.data);
            setMessageListForSingleObject(sortMessages);
        });
    };

    const saveMessage = async (data) => {
        await MessagesService.createMessages(data).then(() => {
            getMessagesListForSingleObject(data.objectId);
        });
    };

    const toggleReadStatus = async (data, flag) => {
        data.readDate = new Date();
        data.readState = flag.toString();
        data.recipientId = data.recipient.recipientId;
        await MessagesService.toggleReadStatus(data).then(() => {
            getMessagesListForSingleObject(data.objectId);
        });
    };

    const getUnreadMessagesForUser = async () => {
        try {
            const res = await MessagesService.getMessageByRecipientIdAndStatus(user?.userId, false);
            const sortMessages = sortMessagesList(res?.data);
            setMessageListForUserDashboard(sortMessages);
        } catch (err) {
            console.log(err);
        }
    };

    const sortMessagesList = (data) => {
        return data?.sort((dateA, dateB) => new Date(dateB.createdDate) - new Date(dateA.createdDate));
    };

    useEffect(() => {
        if (user?.userId) {
            (async () => {
                await getUnreadMessagesForUser();
            })();
        }
    }, [user?.userId]);

    const contextValue = useMemo(() => ({
        messageListForSingleObject,
        saveMessage,
        toggleReadStatus,
        getMessagesListForSingleObject,
        getUnreadMessagesForUser,
        messageListForUserDashboard
    }), [messageListForSingleObject, messageListForUserDashboard, saveMessage, toggleReadStatus]);

    return (
        <MessagesContext.Provider value={contextValue}>
            {children}
        </MessagesContext.Provider>
    );
};
