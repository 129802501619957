
export const optimisticDataActorMachineGuardsBuilder = ({ guardsOptions, dataActorId, entity }) => {
    const hasTransientData = (context) => {
        return context.transientData && context.transientData.length > 0;
    };
    const notAtMaxPollerDelayAndHasTransientData = (context) => {
        return context.currentPollingDelay !== context.maxPollingDelay && context.transientData && context.transientData.length > 0;
    };

    const shouldAddObjectToTransientData = (context, event) => {
        const { transientObject } = event;
        if (guardsOptions?.shouldAddObjectToTransientDataCallback && typeof guardsOptions?.shouldAddObjectToTransientDataCallback === "function") {
            return guardsOptions.shouldAddObjectToTransientDataCallback({ object: transientObject });
        }
        return true;
    };

    const shouldAddObjectListToTransientData = (context, event) => {
        const { transientObjectList } = event;
        if (guardsOptions?.shouldAddObjectListToTransientDataCallback && typeof guardsOptions?.shouldAddObjectListToTransientDataCallback === "function") {
            return guardsOptions.shouldAddObjectListToTransientDataCallback({ objectList: transientObjectList });
        }
        return true;
    };

    const freshDataIsPresent = (context, event) => event?.data?.freshData?.length > 0;

    const projectionNotLoadedYet = (context, event) => {
        if (!event?.projectionAttributeList || context?.evaluatedData?.length > 0) {
            return true;
        }

        return event?.projectionAttributeList?.every((projection) => context?.loadedProjectionAttributeList?.includes(projection)) === false;
    };

    const hasAdditionalJsonLogicQueryObject = (context, event) => event?.additionalJsonLogicQueryObject || event?.data?.additionalJsonLogicQueryObject;

    const hasPreFilterEntityMap = (context, event) => event?.preFilterEntityMap || event?.data?.preFilterEntityMap;

    const hasViewOrAlertChanged = (context, event) => {
        const contextList = context.projectionAttributeList || [];
        const eventList = event.projectionAttributeList || [];

        if (contextList.length !== eventList.length) {
            return true;
        }

        return !contextList.every((attr, index) => attr === eventList[index]);
    };

    const hasNewAdditionalJsonLogicQueryObject = (context, event) => {
        // First, check if there's an additionalJsonLogicQueryObject in the event
        if (!event?.additionalJsonLogicQueryObject) {
            return false;
        }

        // If there's an existing object in the context, compare them
        if (context.latestAdditionalJsonLogicQueryObject) {
            const currentObjStr = JSON.stringify(context.latestAdditionalJsonLogicQueryObject);
            const nextObjStr = JSON.stringify(event.additionalJsonLogicQueryObject);
            return currentObjStr !== nextObjStr;
        }

        // If there's no existing object in the context, but there's one in the event, return true
        return true;
    };

    const hasDataLoadError = (context) => !!context.loadingDataError;

    const shouldOptimisticallyAddObject = (context, event) => event.optimisticUpdate === true;

    const eventOriginatorIsNotThisDatActor = (context, event) => event.originator !== dataActorId && event.entity === entity;

    const pollerErrorRetryLimitReached = (context) => context.currentPollerRetryCount === context.pollerErrorRetryLimit;

    return {
        hasTransientData,
        notAtMaxPollerDelayAndHasTransientData,
        shouldAddObjectToTransientData,
        freshDataIsPresent,
        projectionNotLoadedYet,
        hasAdditionalJsonLogicQueryObject,
        hasNewAdditionalJsonLogicQueryObject,
        hasPreFilterEntityMap,
        hasViewOrAlertChanged,
        hasDataLoadError,
        shouldOptimisticallyAddObject,
        shouldAddObjectListToTransientData,
        eventOriginatorIsNotThisDatActor,
        pollerErrorRetryLimitReached
    };
};
