import { assign, createMachine } from "xstate";
import { SavedQueryService } from "@app/services/savedQueryService";

export const generateEntitySavedQueriesCountsMachine = ({ dataActorOptions }) => {
    const { entity = "", savedQueryIdList = [], additionalJsonLogicQueryObject = null, additionalSavedQueryIdList = [] } = dataActorOptions;
    return createMachine(
        {
            id: `entitySavedQueriesCountsMachine`,
            context: {
                entity,
                savedQueryIdList,
                additionalJsonLogicQueryObject,
                activeSavedQueryId: null,
                savedQueryCountsByIdMap: {},
            },
            /**
             * @TODO initial value should be 'decidingHowToFetch' when ready to run
             */
            initial: "decidingHowToFetch",
            states: {
                notFetching: {},
                decidingHowToFetch: {
                    always: [
                        {
                            target: "gettingRelevantButNotActiveSavedQueryCounts",
                            cond: "hasActiveSavedQueryAndSavedQueryIdList",
                        },
                        {
                            target: "gettingRelevantSavedQueryCounts",
                            cond: "hasSavedQueryIdList",
                        },
                        {
                            target: "gettingAllSavedQueryCounts",
                        },
                    ],
                },
                gettingRelevantButNotActiveSavedQueryCounts: {
                    invoke: {
                        src: "getRelevantButNotActiveSavedQueryCounts",
                        onDone: {
                            actions: "setReceivedSavedQueryCounts",
                            target: "waitingBeforeNextFetch",
                        },
                    },
                },
                gettingAllSavedQueryCounts: {
                    invoke: {
                        src: "getAllEntitySavedQueryCounts",
                        onDone: {
                            actions: "setReceivedSavedQueryCounts",
                            target: "waitingBeforeNextFetch",
                        },
                    },
                },
                gettingRelevantSavedQueryCounts: {
                    invoke: {
                        src: "getRelevantSavedQueryCounts",
                        onDone: {
                            actions: "setReceivedSavedQueryCounts",
                            target: "waitingBeforeNextFetch",
                        },
                    },
                },
                waitingBeforeNextFetch: {
                    /**
                     * @TODO Enable polling when done
                     */
                    // after: {
                    //     4000: {
                    //         target: "decidingHowToFetch",
                    //     },
                    // },
                },
            },
            on: {
                STOP_POLLING: "notFetching",
                START_POLLING: "decidingHowToFetch",
                ENTITY_FRESH_DATA_LOADED: {
                    target: "decidingHowToFetch",
                    cond: (context, event) => event.entity === entity
                },
                RELOAD_SAVED_QUERY_COUNTS: "decidingHowToFetch"
            },
        },
        {
            actions: {
                setReceivedSavedQueryCounts: assign({
                    savedQueryCountsByIdMap: (context, event) => {
                        const { savedQueryIdList: currentSavedQueryIdList } = context;
                        const { data: savedQueryWithMatchesObjList } = event;
                        const newSavedQueryCountsByIdMap = savedQueryWithMatchesObjList?.reduce((matchesBySavedQueryIdMap, nextSavedQueryWithMatchesObj) => {
                            return {
                                ...matchesBySavedQueryIdMap,
                                [nextSavedQueryWithMatchesObj.savedQueryId]: Number(nextSavedQueryWithMatchesObj.matches)
                            };
                        }, {});
                        const newCounts = {
                            ...context.savedQueryCountsByIdMap,
                            ...newSavedQueryCountsByIdMap
                        };
                        return currentSavedQueryIdList.reduce((allSavedQueryIdWithCounts, nextSavedQueryId) => {
                            if (allSavedQueryIdWithCounts[nextSavedQueryId]) {
                                return allSavedQueryIdWithCounts;
                            }
                            return {
                                ...allSavedQueryIdWithCounts,
                                [nextSavedQueryId]: 0
                            };
                        }, newCounts);
                    }
                })
            },
            guards: {
                hasActiveSavedQueryAndSavedQueryIdList: (context, event) => false,
                hasSavedQueryIdList: (context, event) =>
                    context.savedQueryIdList.length > 0,
            },
            services: {
                getRelevantSavedQueryCounts: async (context) => {
                    const { entity, savedQueryIdList, additionalJsonLogicQueryObject } = context;
                    return await SavedQueryService.getEntitySavedQueryCountsForSavedQueryIdList({ entity, savedQueryIdList, additionalJsonLogicQueryObject, additionalSavedQueryIdList });
                }
            },

        }
    );
};
