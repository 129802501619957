// @ts-check
import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useAuth } from "@app/auth/context/useAuth";
import { useEntitlements } from "@app/contexts/useEntitlements";
import { matchPath, useLocation } from "react-router-dom";
import { getStaticAndDynamicWorkspaceList } from "./workspaceAssist";
import { LoadingSpinner } from "@app/common/loadingSpinner";
/* eslint max-lines-per-function: "Warn" */

/**
 * Utilities like getting specialty and setting up the column definitions are in the workspaceAssistants file now
 */

/**
 * Workspace information like the available ones, disallowed, and manipulation actions
 * @type {React.Context<WorkspaceContextValue>}
 */
export const WorkspacesContext = createContext({});


/**
 * @returns {WorkspaceContextValue}
 */
export const useWorkspaces = () => useContext(WorkspacesContext);

export const WorkspacesWrapper = ({ children }) => {
    const location = useLocation();
    const { isAdmin, isSuperAdmin } = useAuth();
    const { enabledWorkspaceList } = useEntitlements();
    const { workspaceList } = useMemo(() => getStaticAndDynamicWorkspaceList({ enabledWorkspaceList, isAdmin, isSuperAdmin }), [enabledWorkspaceList, isAdmin, isSuperAdmin]);
    const currentWorkspace = useMemo(() => workspaceList?.find(workspace => matchPath({ path: workspace?.specifications?.path ?? "" }, location.pathname)), [location.pathname, workspaceList]);
    /**
     * @callback findWorkspaceObjBasedOnId
     * @param {string} id - ID of workspace to look for
     * @returns {SweftWorkspaceStaticConfig | undefined}
     */
    const findWorkspaceObjBasedOnId = useCallback((id) => workspaceList?.find(workspace => workspace.id === id), [workspaceList]);

    /**
     *
     * @param {string} primaryEntity
     * @param {*} workspaceConfigs
     * @returns {unknown} getWorkspaceObject
     */
    const findWorkspaceObjectBasedOnEntity = useCallback((primaryEntity) => {
        return workspaceList?.find(workspace => workspace.primaryEntity === primaryEntity);
    }, [workspaceList]);

    /**
     * @type {WorkspaceContextValue}
     */
    const contextValue = useMemo(() => ({
        workspaceList,
        currentWorkspace,
        findWorkspaceObjectBasedOnEntity,
        findWorkspaceObjBasedOnId,
    }), [workspaceList, currentWorkspace, findWorkspaceObjBasedOnId]);

    if (!workspaceList) {
        return <LoadingSpinner/>;
    }

    return (
        <WorkspacesContext.Provider value={contextValue}>
            {children}
        </WorkspacesContext.Provider>
    );
};


