import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LeftNavComponent from './leftNavComponent';
import { useWorkspaces } from '@app/workspace/contexts/useWorkspaces';

export const createMenuItems = (workspaceList) => {
    return workspaceList.map(workspace => {
        const { specifications } = workspace;
        return {
            id: workspace.id,
            linkTo: specifications?.path?.replace("/*", "") ?? "",
            icon: `icon-${specifications?.layoutConfig?.icon}`,
            text: workspace.displayName,
            order: specifications?.order
        };
    });
};


const LeftNavContainer = (props) => {
    const [isModalOpen, setisModalOpen] = useState(false);
    const [modalName, setModalName] = useState('');
    const { workspaceList } = useWorkspaces();

    const menuItems = useMemo(() => {
        const menu = createMenuItems(workspaceList);
        menu.sort((left, right) => left.order - right.order);
        return menu;
    }, [workspaceList]);

    const modalOpen = () => {
        setisModalOpen(true);
    };

    const modalClose = () => {
        setisModalOpen(false);
    };

    const triggerModalOpen = (target) => {
        setModalName(target);
        modalOpen();
    };

    return (
        <LeftNavComponent
            modalClose={modalClose}
            props={props}
            isModalOpen={isModalOpen}
            modalName={modalName}
            triggerModalOpen={triggerModalOpen}
            menuItems={menuItems}
        />
    );
};

LeftNavContainer.propTypes = {
    logout: PropTypes.func,
    user: PropTypes.object,
};

export default LeftNavContainer;
