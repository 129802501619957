/* eslint-disable max-lines */

import { actions, assign } from "xstate";
import {
    evaluateBobj,
    evaluateBobjList,
    evaluateBobjListWithFormulaList,
    evaluateSingleBobjWithFormulaList,
    FRESHNESS_PROCESSING_STRATEGY
} from "@app/data/utils";
import { getFormulaToEvaluateList, mergeBobj } from "./utils";
import { getUniqueValueList } from "@app/common/utils";
import { SweftError } from "@app/common/SweftError";

const { pure, stop } = actions;

// eslint-disable-next-line max-lines-per-function
export const staticActions = {
    addObjectForCreatingToTransientData: assign({
        transientData: (context, event) => {
            const { transientObject } = event;
            return [
                ...context.transientData,
                {
                    object: transientObject,
                },
            ];
        },
    }),
    addObjectListForCreatingToTransientData: assign({
        transientData: (context, event) => {
            const { transientObjectList } = event;
            return [
                ...context.transientData,
                ...transientObjectList.map((transientObject) => {
                    return {
                        object: transientObject,
                    };
                }),
            ];
        },
    }),
    addObjectForUpdatingToTransientData: assign({
        transientData: (context, event) => {
            const { keyProperty, transientData } = context;
            const { transientObject, onlyForPolling } = event;
            const existingTransientObjectWrapper = transientData.find(({ object: dataObj }) => dataObj[keyProperty] === transientObject[keyProperty]);
            if (existingTransientObjectWrapper) {
                const restOfTheTransientData = transientData.filter(({ object: dataObj }) => dataObj[keyProperty] !== transientObject[keyProperty]);
                return [
                    ...restOfTheTransientData,
                    {
                        object: mergeBobj({ currentBobj: existingTransientObjectWrapper.object, newBobj: transientObject, keyProperty }),
                        onlyForPolling,
                    },
                ];
            }
            return [
                ...transientData,
                {
                    object: transientObject,
                    onlyForPolling,
                },
            ];
        },
    }),
    addObjectListForUpdatingToTransientData: assign({
        transientData: (context, event) => {
            const { keyProperty, transientData } = context;
            const { transientObjectList, onlyForPolling } = event;
            return transientObjectList.map((transientObject) => {
                const existingTransientObjectWrapper = transientData.find(({ object: dataObj }) => dataObj[keyProperty] === transientObject[keyProperty]);
                if (existingTransientObjectWrapper) {
                    return {
                        object: mergeBobj({ currentBobj: existingTransientObjectWrapper.object, newBobj: transientObject, keyProperty }),
                        onlyForPolling,
                    };
                }
                return {
                    object: transientObject,
                    onlyForPolling,
                };
            });
        },
    }),
    addTransientChangeObject: assign({
        transientChangeDataMap: (context, event) => {
            const { keyProperty, transientChangeDataMap } = context;
            const { transientChangeObject } = event;
            const keyValue = transientChangeObject[keyProperty];
            const existingTransientChangeData = transientChangeDataMap[keyValue];
            if (existingTransientChangeData) {
                return {
                    ...transientChangeDataMap,
                    [keyValue]: {
                        object: mergeBobj({ currentBobj: existingTransientChangeData.object, newBobj: transientChangeObject, keyProperty }),
                    },
                };
            }
            return {
                ...transientChangeDataMap,
                [keyValue]: {
                    object: transientChangeObject,
                },
            };
        },
    }),
    addTransientChangeObjectList: assign({
        transientChangeDataMap: (context, event) => {
            const { keyProperty, transientChangeDataMap } = context;
            const { transientChangeObjectList } = event;
            const newTransientChangeDataMap = transientChangeDataMap;
            transientChangeObjectList.forEach((transientChangeObject) => {
                const keyValue = transientChangeObject[keyProperty];
                const existingTransientChangeData = transientChangeDataMap[keyValue];
                if (existingTransientChangeData) {
                    newTransientChangeDataMap[keyValue] = {
                        object: mergeBobj({ currentBobj: existingTransientChangeData.object, newBobj: transientChangeObject, keyProperty }),
                    };
                } else {
                    newTransientChangeDataMap[keyValue] = {
                        object: transientChangeObject,
                    };
                }
            });
            return newTransientChangeDataMap;
        },
    }),
    addObjectOptimistically: assign({
        evaluatedData: (context, event) => {
            const { keyProperty, evaluatedData } = context;
            const { transientObject } = event;
            return evaluatedData.map((obj) => {
                if (obj[keyProperty] !== transientObject[keyProperty]) {
                    return obj;
                }
                return transientObject;
            });
        },
    }),
    addObjectListOptimistically: assign({
        evaluatedData: (context, event) => {
            const { keyProperty, evaluatedData } = context;
            const { transientObjectList } = event;
            const transientObjectKeyList = transientObjectList.map((transientObject) => transientObject[keyProperty]);
            return evaluatedData.map((obj) => {
                const foundIndex = transientObjectKeyList.indexOf(obj[keyProperty]);
                if (foundIndex === -1) {
                    return obj;
                }
                return transientObjectList[foundIndex];
            });
        },
    }),
    setLoadedData: assign({
        evaluatedData: (context, event) => {
            const { receiveEvaluatedBobj, entityFormulaPathMap } = context;
            const dataToSet = event?.data ? event?.data?.loadedData : event?.loadedData;
            const requestProjectionAttributeList = event?.data ? event?.data?.requestProjectionAttributeList : event?.requestProjectionAttributeList;
            const dataMappingModuleConfig = event?.data ? event?.data?.dataMappingModuleConfig : event?.dataMappingModuleConfig;

            if (receiveEvaluatedBobj) {
                return dataToSet;
            }

            if (!entityFormulaPathMap) {
                return evaluateBobjList({ bobjList: dataToSet });
            }

            const formulaToEvaluateList = getFormulaToEvaluateList({ projectionAttributeList: requestProjectionAttributeList, entityFormulaPathMap });

            return evaluateBobjListWithFormulaList({ bobjList: dataToSet, formulaList: formulaToEvaluateList, dataMappingModuleConfig });
        },
        loadDataActorList: (context, event) => {
            return context.loadDataActorList.filter((dataActorObj) => dataActorObj.loadDataMachineId !== event?.loadDataMachineId);
        },
    }),
    addLoadedData: assign({
        evaluatedData: (context, event) => {
            const { keyProperty, receiveEvaluatedBobj, entityFormulaPathMap } = context;
            const requestProjectionAttributeList = event?.data ? event?.data?.requestProjectionAttributeList ?? [] : event?.requestProjectionAttributeList ?? [];
            const dataMappingModuleConfig = event?.data ? event?.data?.dataMappingModuleConfig : event?.dataMappingModuleConfig;
            const alertMappingModuleConfig = event?.data ? event?.data?.alertMappingModuleConfig : event?.alertMappingModuleConfig;

            if (event.loadedData?.responseType === "error") {
                return event.loadedData;
            }

            const formulaToEvaluateList = getFormulaToEvaluateList({ projectionAttributeList: requestProjectionAttributeList, entityFormulaPathMap });

            if (context.evaluatedData.length === 0) {
                return !receiveEvaluatedBobj && !entityFormulaPathMap ? evaluateBobjList({ bobjList: event.loadedData }) : evaluateBobjListWithFormulaList({ bobjList: event.loadedData, formulaList: formulaToEvaluateList, dataMappingModuleConfig, alertMappingModuleConfig });
            }
            const newCombinedData = event.loadedData.map((newBobj) => {
                const currentBobj = context.evaluatedData?.find((curObj) => curObj[keyProperty] === newBobj[keyProperty]);
                return mergeBobj({ currentBobj, newBobj, keyProperty });
            });
            return !receiveEvaluatedBobj && !entityFormulaPathMap ? evaluateBobjList({ bobjList: newCombinedData }) : evaluateBobjListWithFormulaList({ bobjList: newCombinedData, formulaList: formulaToEvaluateList, dataMappingModuleConfig, alertMappingModuleConfig });
        },
        loadDataActorList: (context, event) => {
            return context.loadDataActorList.filter((dataActorObj) => dataActorObj.loadDataMachineId !== event?.loadDataMachineId);
        },
    }),
    processFreshData: assign((context, event) => {
        const { transientData, evaluatedData, keyProperty, freshnessProcessingStrategy, receiveEvaluatedBobj, transientChangeDataMap, entityFormulaPathMap, projectionAttributeList } = context;
        const newEvaluatedData = evaluatedData;
        const newTransientData = transientData;
        let newTransientChangeDataMap = transientChangeDataMap;
        const freshData = event.data.freshData;
        const formulaToEvaluateList = getFormulaToEvaluateList({ projectionAttributeList, entityFormulaPathMap });
        // Get the fresh data that will be moved from transientData to data
        freshData.forEach((freshDataObj) => {
            const evaluatedFreshDataObj = !receiveEvaluatedBobj && !entityFormulaPathMap ? evaluateBobj({ bobj: freshDataObj }) : evaluateSingleBobjWithFormulaList({ bobj: freshDataObj, formulaList: formulaToEvaluateList });
            const foundTransientDataObjIndex = newTransientData.findIndex(({ object: transientDataObj }) => evaluatedFreshDataObj[keyProperty] === transientDataObj[keyProperty]);
            let currentObject = evaluatedFreshDataObj;
            let transientObj;
            let onlyForPolling;
            if (foundTransientDataObjIndex > -1) {
                transientObj = newTransientData[foundTransientDataObjIndex].object;
                onlyForPolling = newTransientData[foundTransientDataObjIndex].onlyForPolling;
                newTransientData.splice(foundTransientDataObjIndex, 1);
                const keyValue = transientObj[keyProperty];
                const {
                    // eslint-disable-next-line no-unused-vars
                    [keyValue]: _removedValue,
                    ...restOfNewTransientChangeDataMap
                } = newTransientChangeDataMap;
                newTransientChangeDataMap = restOfNewTransientChangeDataMap;
            }
            const indexOnData = evaluatedData.findIndex((bobj) => bobj[keyProperty] === currentObject[keyProperty]);
            if (indexOnData > -1) {
                if (onlyForPolling) {
                    newEvaluatedData.splice(indexOnData, 1);
                } else if (freshnessProcessingStrategy === FRESHNESS_PROCESSING_STRATEGY.REPLACE) {
                    newEvaluatedData[indexOnData] = currentObject;
                } else if (freshnessProcessingStrategy === FRESHNESS_PROCESSING_STRATEGY.KEEP_TRANSIENT) {
                    newEvaluatedData[indexOnData] = { ...transientObj };
                } else {
                    currentObject = mergeBobj({ currentBobj: transientObj, newBobj: currentObject });
                    // Assume default freshness processing strategy of merge
                    newEvaluatedData[indexOnData] = mergeBobj({ currentBobj: newEvaluatedData[indexOnData], newBobj: currentObject, keyProperty });
                }
            } else {
                newEvaluatedData.unshift(currentObject);
            }
        });

        return {
            evaluatedData: newEvaluatedData,
            transientData: newTransientData,
            transientChangeDataMap: newTransientChangeDataMap,
        };
    }),
    resetPollerDelay: assign({
        currentPollingDelay: (context) => context.startingPollingDelay,
    }),
    increasePollerDelay: assign({
        currentPollingDelay: (context) => {
            const nextPollingDelay = context.currentPollingDelay * context.pollingDelayMultiplier;
            if (nextPollingDelay > context.maxPollingDelay) {
                return context.maxPollingDelay;
            }
            return nextPollingDelay;
        },
    }),
    updateObjectInTransientData: assign({
        transientData: (context, event) => {
            const { keyProperty } = context;
            const { object } = event;
            return context.transientData.map((transientDataObj) => {
                const { object: transientObject } = transientDataObj;
                if (object[keyProperty] !== transientObject[keyProperty]) {
                    return transientDataObj;
                }
                return {
                    ...transientDataObj,
                    object,
                };
            });
        },
    }),
    addProjectionsToAlreadyLoadedList: assign({
        loadedProjectionAttributeList: (context, event) => getUniqueValueList([...context.loadedProjectionAttributeList, ...(event?.projectionAttributeList ?? [])])
    }),
    setNewAdditionalJsonLogicQueryObject: assign({
        latestAdditionalJsonLogicQueryObject: (context, event) => event?.additionalJsonLogicQueryObject,
    }),
    setLoadingDataError: assign({
        loadingDataError: (context, event) => {
            const { errorObj } = event;
            return errorObj;
        },
    }),
    stopDataLoaders: pure((context) => {
        return [
            ...context.loadDataActorList.map((loadDataActorObj) => stop(loadDataActorObj.loadDataMachineId)),
            assign({
                loadDataActorList: () => [],
            }),
        ];
    }),
    clearLoadingDataError: assign({
        loadingDataError: () => null,
    }),
    addToProjectionAttributeList: assign({
        projectionAttributeList: (context, event) => {
            const { attributeList = [] } = event;
            if (Array.isArray(context?.projectionAttributeList)) {
                const combinedList = [...context.projectionAttributeList, ...attributeList];
                return getUniqueValueList(combinedList);
            }
            return getUniqueValueList(attributeList);
        },
    }),
    removeFromProjectionAttributeList: assign({
        projectionAttributeList: (context, event) => {
            const { attributeList = [] } = event;
            return context?.projectionAttributeList?.filter((attribute) => !attributeList.includes(attribute));
        },
    }),
    clearPollerRetry: assign({
        currentPollerRetryCount: () => 0,
        pollerRetryError: () => null,
    }),
    setPollerRetryLimitReachedError: assign({
        pollerRetryError: () => new SweftError(`Poller error retry limit reached.`)
    }),
    increasePollerRetryLimit: assign({
        currentPollerRetryCount: (context) => context.currentPollerRetryCount + 1
    }),
    clearTransientData: assign({
        transientData: [],
        transientChangeDataMap: {},
    })
};
