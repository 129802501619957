import ConfigurationService from "@app/services/configurationService";
import { generateOptimisticDataMachine } from "@app/data/machine/actors/optimisticDataActorMachine";
import { FRESHNESS_PROCESSING_STRATEGY } from "@app/data/utils";

const workspacesConfigMachineGeneratorProps = {
    type: "workspacesConfig",
    keyProperty: "configurationId",
    createMachineOptions: {
        services: {
            createService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    ConfigurationService.saveSuperAdminWorkspaceConfig(object).then(() => {
                        resolve({ newObject: object });
                    });
                });
            }
        }
    },
    updateMachineOptions: {
        services: {
            updateService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    ConfigurationService.saveSuperAdminWorkspaceConfig(object).then(() => {
                        resolve({ newObject: object });
                    });
                });
            }
        }
    },
    deleteMachineOptions: {
        services: {
            deleteService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    ConfigurationService.removeWorkspaceConfig(object).then(() => {
                        resolve({ deletedObject: object });
                    });
                });
            }
        }
    },
    services: {
        loadService: async () => {
            return new Promise((resolve) => {
                ConfigurationService.getWorkspaceConfigList().then((workspaceConfigList) => {
                    resolve({
                        loadedData: workspaceConfigList
                    });
                });
            });
        },
        loadDataFilteredForTransientData: async () => {
            return new Promise((resolve) => {
                ConfigurationService.getWorkspaceConfigList().then((workspaceConfigList) => {
                    resolve({
                        loadedData: workspaceConfigList
                    });
                });
            });
        }
    },
    transientConfig: {
        transientProperty: "modifiedTimestamp",
        transientValueOnCreate: () => 0,
        transientValueOnUpdate: (obj) => obj.modifiedTimestamp,
        freshnessProcessingStrategy: FRESHNESS_PROCESSING_STRATEGY.KEEP_TRANSIENT
    }
};

export const workspacesConfigDataActorMachine = generateOptimisticDataMachine(workspacesConfigMachineGeneratorProps);
