import jsonLogic from "json-logic-js";
import { SweftOperators } from "@app/grid/advancedFilters/operators";
import moment from "moment";

export const now = () => {
    return new Date();
};

/**
 * Use to simply plus/minus number of d/w/m/y from current date
 * Like -> Today + 5 days -> Add 5 days to the current date
 * Like -> Today - 5 days -> Subtract 5 days from the current date
 */

export function date_add (_now = now(), duration, period) {
    const mapTimePeriodToMomentFormat = {
        day: 'd',
        week: 'w',
        month: 'M',
        year: 'y',
    };

    return new Date(moment(_now).add(duration, mapTimePeriodToMomentFormat[period]));
}

jsonLogic.add_operation("SweftOperators",SweftOperators);
jsonLogic.add_operation("now",now);
jsonLogic.add_operation("date_add",date_add);

export const applyAdvancedFilters = ({ logic, rows }) => {
    try {
        return rows.filter((row) => jsonLogic.apply(logic, row));
    } catch (e) {
        return [];
    }
};
