import React, { useCallback, useMemo } from "react";
import { AlertsContext } from "@app/contexts/useAlerts/context";
import { useData } from "@app/data/context";
import { useSelector } from "@xstate/react";
import { isEqual } from "lodash";
import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";
import { useStageStatusOrder } from "@app/dashboard/useStageStatusOrder";
import { useSpecialty } from "@app/specialty/hooks/useSpecialty";
import { flagNames, isFlagOn } from "@app/common/featureFlags";


const alertsDataActorObjSelector = (state) => state.context.alertsDataActor;


export const SavedQueryAlertsContextProvider = ({ children }) => {
    const { specialty } = useSpecialty();
    const { dataMachineService } = useData();
    const { findWorkspaceObjBasedOnId } = useWorkspaces();
    const { stageStatusOrder } = useStageStatusOrder();
    const alertsDataActorObj = useSelector(dataMachineService, alertsDataActorObjSelector, isEqual);
    const { userAlertListByEntityMap, savedQueryIdByAlertIdMap, userSpecialty } = useSelector(alertsDataActorObj.ref, (state) => state.context, isEqual);
    const hydratedAlertList = useMemo(() => Object.values(userAlertListByEntityMap).flat().map((alertObj) => {
        const workspaceObj = findWorkspaceObjBasedOnId(alertObj.workspace);
        let additionalJsonLogicQueryObject = null;
        if (specialty?.isEnabled && !isFlagOn(flagNames.savedQueryAlerts)) {
            const alertWorkspaceSpecialty = userSpecialty?.find((workspaceSpecialtyObj) => workspaceSpecialtyObj.workspace = alertObj.workspace);
            additionalJsonLogicQueryObject = alertWorkspaceSpecialty?.savedQueryObject ?? null;
        }
        const savedQueryId = savedQueryIdByAlertIdMap[alertObj.alertId];
        return {
            ...alertObj,
            savedQueryId,
            path: `${workspaceObj?.specifications?.path}?alert=${alertObj.alertId}&savedQueryId=${savedQueryId}${alertObj.view ? `&view=${alertObj.view}` : '' }`,
            additionalJsonLogicQueryObject
        };
    }), [userAlertListByEntityMap, findWorkspaceObjBasedOnId, specialty?.isEnabled, savedQueryIdByAlertIdMap, userSpecialty]);

    const alerts = useMemo(() => hydratedAlertList.filter(({ typeKey }) => !typeKey || typeKey === 'alert' ), [hydratedAlertList]);

    const stageStatusList = useMemo(() => hydratedAlertList.filter(({ typeKey }) => typeKey === 'stageStatus' ).sort((a,b) => {
        return stageStatusOrder.indexOf(a.alertId) - stageStatusOrder.indexOf(b.alertId);
    }), [hydratedAlertList, stageStatusOrder]);

    const specialtyList = useMemo(() => hydratedAlertList.filter(({ typeKey }) => typeKey === 'specialty' ), [hydratedAlertList]);

    const getAlertById = useCallback((alertId) => {
        return hydratedAlertList?.find((alertObj) => alertObj.alertId === alertId);
    }, [hydratedAlertList]);

    /**
     * @type {{alerts: unknown[], getAlertById: function(*): unknown, stageStatusList: unknown[]}}
     */
    const contextValue = useMemo(() => ({
        alerts,
        getAlertById,
        stageStatusList,
        hydratedAlertList,
        specialtyList
    }), [alerts, getAlertById, stageStatusList, hydratedAlertList]);
    return (
        <AlertsContext.Provider value={contextValue}>{children}</AlertsContext.Provider>
    );
};
