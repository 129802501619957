import { flagNames, isFlagOn } from "@app/common/featureFlags";

/**
 * Returns the visible column list for super admin based on state conditions, such as feature flags
 * @param columnList
 * @returns {*}
 */
export const getVisibleColumnListForSuperAdmin = ({ columnList = [] } = {}) => {
    const disabledAttributesHiddenForSuperAdmin = isFlagOn(flagNames.disabledAttributesHiddenForSuperAdmin);
    if (disabledAttributesHiddenForSuperAdmin) {
        return columnList.filter((col) => col.state === 'On');
    }
    return columnList;
};
/**
 * Get related attribute maps for each entity found from the column list
 * @param columnList
 * @returns {{entityToRelatedAttributeMap: {}, entitiesToRelatedEntityMap: {}}}
 */
export const createEntityToRelatedAttributeMaps = ({ columnList = [] } = {}) => {
    const entityToRelatedAttributeMap = {};
    const entitiesToRelatedEntityMap = {};
    const entitiesFormulaMap = {};
    const entitySet = new Set(columnList.map(cols => cols.entity));

    entitySet.forEach((entity) => {
        const relatedAttributeSet = new Set();
        const relatedEntitySet = new Set();
        columnList.forEach((column) => {
            if (entity === column?.entity) {
                if (entitySet.has(column?.relation)) {
                    relatedAttributeSet.add(column);
                    relatedEntitySet.add(column?.relation);
                }
                if (column?.baseType?.toLowerCase() === 'formula') {
                    entitiesFormulaMap[entity] = {
                        ...(entitiesFormulaMap[entity] ?? {}),
                        [`${column.schemaId}`]: {
                            path: column.schemaId.split('.')[1],
                            ...column.formulaDefinition
                        }
                    };
                }
            }
        });
        entityToRelatedAttributeMap[entity] = Array.from(relatedAttributeSet);
        entitiesToRelatedEntityMap[entity] = Array.from(relatedEntitySet);
    });

    return { entityToRelatedAttributeMap, entitiesToRelatedEntityMap, entitiesFormulaMap };
};
