// @ts-check
import React from 'react';
import moment from 'moment';
import { Button, List, Row, Col } from "antd";

/**
 * Type for incoming message list
 *  @typedef {{associatedProperty: {associatedPropertyName: string, associatedPropertyValue: string}, createdDate: string, objectId: string, messageBody: string | React.ReactNode, messageId: string, readDate: string, readState: 'true'|'false', recipient: {recipientNickname: string, recipientId: string}, sender: {senderNickname: string, senderId: string}}} messageItem
 **/


/**
 * @prop {messageItem[]} messageListForSingleObject
 * @prop {boolean} showCommentsCount
 * @prop {boolean} showRecipient
 * @prop {(arg0: any, arg1: boolean) => void} toggleReadStatus
 * @prop {{userName: string, [key:string]:any}} user Partial user at least
 * @prop {{prodName: string|React.ReactNode}} data
 * @prop {()=>any} createNewMessage
 * @prop {()=>any} addNewMessageSection
 * @returns {JSX.Element}
 **/
export const RenderListOfMessages = (props) => {
    return (
        <>
            {
                props.showCommentsCount &&
                <Row>
                    <Col span={12}><b>{props?.data?.prodName}</b></Col>
                    <Col span={12}><b>COMMENTS: {props.messageListForSingleObject?.length ?? 0}</b></Col>
                </Row>
            }
            <List
                itemLayout="vertical"
                size="small"
                dataSource={props.messageListForSingleObject ?? []}
                header={
                    props.createNewMessage &&
                    props.addNewMessageSection()
                }
                renderItem={(/** @type {messageItem} item **/ item) => (
                    <List.Item
                        key={item.createdDate}
                        style={{
                            'border': '1px solid #f0f0f0',
                            'backgroundColor': (item.readState === 'true') ? '#EFF2F3' : '',
                            'padding': '2px 5px',
                        }}
                        className="mb-2 cursor-pointer"
                        onDoubleClick={() => props.redirectToWorkspace && props.redirectToWorkspace(item)}
                    >
                        <List.Item.Meta
                            description={
                                <Row>
                                    {
                                        props.showRecipient &&
                                        <Col span={8}>To: {item.recipient.recipientNickname}</Col>
                                    }
                                    <Col span={8}>From: {item.sender.senderNickname}</Col>
                                    <Col span={8} className='text-teal-600 truncate'>{item.associatedProperty.associatedPropertyValue}</Col>
                                </Row>
                            }
                            style={{
                                'marginBottom': '0'
                            }}
                        />
                        {item.messageBody}
                        <br />
                        <Row>
                            <Col span={12} style={{ color: "#7db0de" }}>{moment(item.createdDate).format("MM/DD/YYYY, h:mm a")}</Col>
                            {
                                (item.readState === 'true') &&
                                <>
                                    <Col span={6} style={{ color: "#7db0de" }}>{moment(item.readDate).format("MM/DD/YYYY, h:mm a")}</Col>
                                    <Col span={6}><Button size="small" key="unreadButton" type="primary" onClick={() => props.toggleReadStatus(item, false)}>Mark as unread</Button></Col>
                                </>
                            }
                            {((item.readState === 'false') && `${props?.user?.firstName} ${props?.user?.lastName}` == item.recipient.recipientNickname) &&
                                <Button key="readButton" size="small" type="primary" onClick={() => props.toggleReadStatus(item, true)}>Mark as read</Button>
                            }
                        </Row>
                    </List.Item>
                )}
            />
        </>
    );
};
