// @ts-check
import React, { Component } from 'react';
import './stylesheets/error-boundry.less';
import { ExclamationCircleFilled } from "@ant-design/icons";

class ErrorBoundryComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };

        this.childProps = this.props && this.props.children && this.props.children.props;
        this.rollBarConfig = {
            accessToken: 'c99f9e6ca09b433bab7b474e6a286eb6',
            captureUncaught: true,
            captureUnhandledRejections: true
        };

        this.getUserInfo = this.getUserInfo.bind(this);
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true
        };
    }

    getUserInfo() {
        if (this.childProps && this.childProps.user) {
            return {
                id: this.childProps.user.email,
                email: this.childProps.user.userId,
                username: this.childProps.user.userName
            };
        }
        return {};
    }

    componentDidMount() {
        window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
            console.warn('Error: ', errorMsg);
            console.warn(' Script: ', url);
            console.warn(' Line: ', lineNumber);
            console.warn(' Column: ', column);
            console.warn(' StackTrace: ', errorObj);
        };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    errorLogout() {
        localStorage.clear();
        // redirect to logout page
        window.location.href = '/logout';
    }

    errorReload() {
        this.setState({
            error: null,
            errorInfo: null
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundry-wrap" >
                    <h2>
                        <ExclamationCircleFilled className="text-7xl" />
                        <br /> < br />
                        Oops! Something went wrong. <br />
                        This page didn 't load correctly, please reload the page to continue. <br /> < br />
                        <div className="flex justify-evenly flex-wrap">
                            <button onClick={this.errorReload.bind(this)} className="error-reload-button"> Continue </button>
                            <button onClick={this.errorLogout.bind(this)} className="error-reload-button"> Logout </button>
                        </div>
                    </h2>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundryComponent;
