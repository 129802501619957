import { AlertService } from "@app/services/alertService";
import { generateOptimisticDataMachine } from "@app/data/machine/actors/optimisticDataActorMachine";
import { FRESHNESS_PROCESSING_STRATEGY } from "@app/data/utils";

const alertsConfigDataActor = {
    type: "alertsConfig",
    keyProperty: "alertId",
    createMachineOptions: {
        services: {
            createService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    AlertService.saveAlert(object).then(() => {
                        resolve({ newObject: object });
                    });
                });
            }
        }
    },
    updateMachineOptions: {
        services: {
            updateService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    AlertService.saveAlert(object).then(() => {
                        resolve({ newObject: object });
                    });
                });
            }
        }
    },
    deleteMachineOptions: {
        services: {
            deleteService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    AlertService.removeAlert(object).then(() => {
                        resolve({ deletedObject: object });
                    });
                });
            }
        }
    },
    services: {
        loadService: async () => {
            return new Promise((resolve) => {
                AlertService.getAlerts().then((newAlerts) => {
                    resolve({ loadedData: newAlerts });
                });
            });
        },
        loadDataFilteredForTransientData: async () => {
            return new Promise((resolve) => {
                AlertService.getAlerts().then((newAlerts) => {
                    resolve({ loadedData: newAlerts });
                });
            });
        }
    },
    transientConfig: {
        transientProperty: "modifiedTimestamp",
        transientValueOnCreate: () => new Date().getTime(),
        transientValueOnUpdate: () => new Date().getTime(),
        freshnessProcessingStrategy: FRESHNESS_PROCESSING_STRATEGY.KEEP_TRANSIENT
    }
};

export const generateAlertsConfigDataActorMachine = ({ noParent } = {}) => generateOptimisticDataMachine({ ...alertsConfigDataActor, noParent });
