import { assign, createMachine } from "xstate";
import { createMappedBobj } from "@app/data/utils";

export const receiveSampleMachine = createMachine({
    id: "receiveSample",
    initial: "checkingForSampleThatHaveNotBeenCreated",
    context: {
        sampleToBeCreatedList: [],
        existingSampleList: [],
        productMappingDependencies: {}
    },
    states: {
        checkingForSampleThatHaveNotBeenCreated: {
            always: [
                {
                    cond: "allSamplesToBeCreatedHaveBeenCreated",
                    target: "addingCreatedSamplesToTheirProducts"
                }, {
                    target: "waiting"
                }
            ]
        },
        addingCreatedSamplesToTheirProducts: {
            entry: "addCreatedSamplesToTheirProducts",
            always: "checkingForSampleThatHaveNotBeenCreated"
        },
        waiting: {}
    },
    on: {
        ADD_SAMPLE_TO_BE_CREATED: {
            actions: "addSampleToBeCreated",
            target: "checkingForSampleThatHaveNotBeenCreated"
        },
        ADD_SAMPLE_LIST_TO_BE_CREATED: {
            actions: "addSampleListToBeCreated",
            target: "checkingForSampleThatHaveNotBeenCreated"
        },
        UPDATE_EXISTING_SAMPLE_LIST: {
            actions: "setExistingSampleList",
            target: "checkingForSampleThatHaveNotBeenCreated"
        },
        SET_PRODUCT_MAPPING_DEPENDENCIES: {
            actions: "setProductMappingDependencies"
        }
    }
}, {
    actions: {
        addSampleToBeCreated: assign({
            sampleToBeCreatedList: (context, event) => ([...context.sampleToBeCreatedList, event.sampleToBeCreated])
        }),
        addSampleListToBeCreated: assign({
            sampleToBeCreatedList: (context, event) => ([...context.sampleToBeCreatedList, ...event.sampleToBeCreatedList])
        }),
        addCreatedSamplesToTheirProducts: assign({
            sampleToBeCreatedList: (context) => {
                const { sampleToBeCreatedList, productMappingDependencies, existingSampleList } = context;
                let newSampleToBeCreatedList = sampleToBeCreatedList;
                const sampleGroupedByProductMap = sampleToBeCreatedList.reduce((productMap, nextSampleToBeCreatedObj) => {
                    const nextProductMap = productMap;
                    const { sampleObj, productObj } = nextSampleToBeCreatedObj;
                    if (productMap[productObj.id]) {
                        nextProductMap[productObj.id].sampleList = [
                            ...productMap[productObj.id].sampleList,
                            sampleObj
                        ];
                    } else {
                        nextProductMap[productObj.id] = {
                            productObj,
                            sampleList: [sampleObj]
                        };
                    }
                    return nextProductMap;
                }, {});

                const { samplesEntityMappedColumnsList, dataMachineService, productDataActorId } = productMappingDependencies;

                Object.keys(sampleGroupedByProductMap).forEach((productId) => {
                    const { productObj, sampleList } = sampleGroupedByProductMap[productId];
                    sampleList.forEach((sampleObj) => {
                        const createdSampleObj = existingSampleList.find((obj) => obj.id === sampleObj.id);
                        createMappedBobj({
                            bobj: { ...createdSampleObj, product: productObj },
                            mappedAttributeList: samplesEntityMappedColumnsList,
                            dataMachineService,
                            mappedBobjDataActorId: productDataActorId,
                            optimisticUpdate: true,
                        });
                        newSampleToBeCreatedList = newSampleToBeCreatedList.filter(({ sampleObj: _sampleObj }) => _sampleObj.id !== sampleObj.id);
                    });
                });
                return newSampleToBeCreatedList;
            }
        }),
        setExistingSampleList: assign({
            existingSampleList: (context, event) => {
                const relevantSampleIdList = context.sampleToBeCreatedList.map(({ sampleObj }) => sampleObj.id);
                return event.existingSampleList.filter((sampleObj) => relevantSampleIdList.includes(sampleObj.id));
            }
        }),
        setProductMappingDependencies: assign({
            productMappingDependencies: (context, event) => event.productMappingDependencies
        }),
    },
    guards: {
        allSamplesToBeCreatedHaveBeenCreated: (context) => {
            if (context.sampleToBeCreatedList.length === 0) {
                return false;
            }
            const { productMappingDependencies } = context;
            const { gidAttributesList } = productMappingDependencies;
            return context.sampleToBeCreatedList.every(({ sampleObj }) => {
                const existingSample = context.existingSampleList.find((existingSampleObj) => existingSampleObj.id === sampleObj.id );
                if (!existingSample) {
                    return false;
                }
                return gidAttributesList.every((attributeObj) => {
                    return !!existingSample[attributeObj.field];
                });
            });
        }
    }
});
