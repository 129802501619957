import React from 'react';
import { useNavigate } from 'react-router-dom';
import './404.less';

const PageNotFound = () => {
    const navigate = useNavigate();

    const redirectBack = () => {
        navigate('/dashboard');
    };

    return (
        <div className="pnf-container">
            <div id="background"></div>
            <div className="top">
                <h1>404</h1>
                <h3>page not found</h3>
            </div>
            <div className="container">
                <div className="ghost-copy">
                    <div className="one"></div>
                    <div className="two"></div>
                    <div className="three"></div>
                    <div className="four"></div>
                </div>
                <div className="ghost">
                    <div className="face">
                        <div className="eye"></div>
                        <div className="eye-right"></div>
                        <div className="mouth"></div>
                    </div>
                </div>
                <div className="shadow"></div>
            </div>
            <div className="buttons">
                <button onClick={redirectBack} className="btn">Take Me Back</button>
            </div>
        </div>
    );
};

export default PageNotFound;