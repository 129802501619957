import React, { useMemo } from "react";
import { DataWrapper } from "@app/data/context";
import { useCurrentUserRole } from "@app/auth/useCurrentUserRole";
import { useSpecialty } from "@app/specialty/hooks/useSpecialty";

export const DataWrapperWithAlerts = ({ children }) => {
    const { currentUserRole } = useCurrentUserRole();
    const { specialty } = useSpecialty();

    const alertsDataActorMachineOptions = useMemo(() => {
        return {
            userRole: currentUserRole,
            userSpecialty: specialty?.config
        };
    }, [currentUserRole, specialty?.config]);

    return <DataWrapper alertsDataActorMachineOptions={alertsDataActorMachineOptions}>{ children }</DataWrapper>;
};
