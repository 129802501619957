import request from './request';
import { v4 as uuidv4 } from 'uuid';
import { baseConfig } from '@app/config/baseConfig';
import { SweftError } from "@app/common/SweftError";

const configurationApi = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/configuration`;

/**
 * Creating this wrapper around alerts that uses the config backend
 * to allow feature flag toggling between new and legacy alerts
 * Once new alerts goes completely alive, remove this and the flag
 */
export const AlertConfigService = {
    /**
     * Check status of the alert legacy service
     * Gives a 401 when no session is available
     * @returns {Promise<unknown>}
     */
    status: async () => {
        try {
            return await request({
                method: "GET",
                url: `${configurationApi}/status`
            });
        } catch (error) {
            throw new SweftError(`Error getting alert configuration service status`, error);
        }
    },
    getAlerts: async () => {
        try {
            const response = await request({
                method: "GET",
                url: `${configurationApi}/alerts`
            });
            return response.data.data;
        } catch (error) {
            throw new SweftError(`Error getting alert list from the configuration service`, error);
        }
    },
    saveAlert: async (config) => {
        if (!config) {
            throw new SweftError(`No alert config passed in to save`);
        }
        try {
            if (!config?.configurationId) {
                config.configurationId = uuidv4();
            }
            const response = await request({
                method: "PUT",
                url: `${configurationApi}/alerts/${config.configurationId}`,
                data: config
            });

            return response.data;
        } catch (error) {
            throw new SweftError(`Error saving the alert with configuration id '${config?.configurationId}'`, error);
        }
    },
    getAlert: async (configId) => {
        try {
            const response = await request({
                method: "GET",
                url: `${configurationApi}/alerts/${configId}`
            });
            return response.data;
        } catch (error) {
            throw new SweftError(`Error getting the alert with configuration id '${configId}'`, error);
        }
    },
    removeAlert: async (config) => {
        try {
            const response = await request({
                method: "DELETE",
                url: `${configurationApi}/alerts/${config.configurationId}`,
            });

            return response.data;
        } catch (error) {
            throw new SweftError(`Error removing the alert with configuration id '${config?.configurationId}'`, error);
        }
    },
};

