import React from "react";

export const DashboardModuleSpecialtyToggle = ({ useSpecialty }) => {
    const { specialty, toggleSpecialty } = useSpecialty();

    if (!specialty?.isAvailable) {
        return null;
    }
    return <button
        onClick={() => toggleSpecialty()}
        className={`block whitespace-nowrap px-2 py-1 hover:bg-gray-200 rounded-md flex items-center gap-2 ${specialty.isEnabled && "bg-gray-100 font-normal"}`}
    >
        {specialty.isEnabled ? "Specialty Active" : "Activate Specialty"}
    </button>;
};

