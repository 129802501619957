import { v5 as uuidv5 } from "uuid";

export const findDataActorObjForConfigFromContext = ({ context, config }) =>
    context.configDataActorList.find((dataActorObj) => dataActorObj.config === config);


export const generateDataActorId = ({ dataActorOptions }) => {
    const { entity, dataActorType, projectionAttributeList, type, withPreFilter, additionalSavedQueryIdList, additionalSavedQueryList } = dataActorOptions;
    const ns_dns = '39115810-a7eb-404c-8e41-89ef277ca908';
    const zone = '/dataactors';
    const zone_uuid = uuidv5(zone, ns_dns);

    return uuidv5(JSON.stringify({ entity, dataActorType, projectionAttributeList, type, withPreFilter, additionalSavedQueryIdList, additionalSavedQueryList }), zone_uuid);
};
