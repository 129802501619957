// @ts-check
import { useCallback, useMemo } from "react";
import { useActor, useSelector } from "@xstate/react";
import { evaluatedDataSelector } from "@app/data/machine/selectors";
import { isEqual } from "lodash";
import { useData } from "@app/data/context";
import { generateDataActorSelector } from "@app/data/machine/generators/generateDataActorSelector";
import { generateDataActorId } from "@app/data/machine/utils";

/**
 * Hook for using a data actor within the global data machine service.
 * When downstream users of the useDataActor hook attempt to access a data actor that has not been spawned,
 * the unknownDataActor is returned and an event is sent to the data machine service to spawn the desired data actor.
 * The unknownDataActor is leveraged.To limit downstream checks of the existence of a data actor.
 *
 * This hook is intended to be re-usable for all different types of data actors.
 * Events should be sent to the data machine service based on the type of data actor that the downstream user
 * of the hook is attempting to access.
 *
 * @returns {{dataActorState: * extends ActorRef<any, infer TEmitted> ? TEmitted : never, dataActorSend: any, dataActorObj: SweftDataMachineDataActor, dataActorData: []}}
 */
export const useDataActor = ({ dataActorOptions = {}, dataActorId: dataActorIdToUse = null, spawnSource = null, noSpawning = false } = {}) => {
    const { dataMachineService, sendToDataMachineService } = useData();

    const dataActorSelector = useCallback(() => generateDataActorSelector({ dataActorOptions, dataActorId: dataActorIdToUse }), [dataActorOptions, dataActorIdToUse]);
    const dataActorObj = useSelector(dataMachineService, dataActorSelector());
    if (dataActorObj.type === "unknownDataActor" && noSpawning === false) {
        sendToDataMachineService({ type: "COMMAND_SPAWN_DATA_ACTOR", dataActorOptions, spawnSource });
    }
    const dataActorId = useMemo(() => {
        if (dataActorIdToUse) {return dataActorIdToUse;}
        if (dataActorObj.type === "unknownDataActor") {
            return null;
        }
        return generateDataActorId({ dataActorOptions });
    }, [dataActorIdToUse, dataActorOptions, dataActorObj.type]);


    const [dataActorState, dataActorSend] = useActor(dataActorObj.ref);

    const dataActorData = useSelector(dataActorObj.ref, evaluatedDataSelector, isEqual);

    return {
        dataActorId,
        dataActorObj,
        dataActorState,
        dataActorSend,
        dataActorData
    };
};
