/**
 * A machine for loading, updating, and creating entities
 * @typedef SweftDataMachineEntitySchemaDataActorMachine
 * @type {StateMachine<SweftDataMachineEntitySchemaDataActorMachineContext,SweftOptimisticDataMachineEvent>}
 */

/**
 * @interface SweftDataMachineEntitySchemaDataActorMachineContext
 * @extends {SweftOptimisticDataMachineContext}
 * @prop {"schemaId"} keyProperty
 */

import { VanguardService } from "@app/services/vanguardService";
import { generateOptimisticDataMachine } from "@app/data/machine/actors/optimisticDataActorMachine";
import ConfigurationService from "@app/services/configurationService";

/**
 * @type {SweftOptimisticDataMachineGeneratorProps}
 */
const entitySchemaMachineGeneratorProps = {
    type: "entitySchema",
    keyProperty: "schemaId",
    createMachineOptions: {
        services: {
            createService: async (context, event) => {
                const { object } = context;
                return new Promise((resolve, reject) => {
                    VanguardService.schema.createEntity(object).then(() => {
                        resolve({ newObject: object });
                    });
                });
            },
        }
    },
    services: {
        loadService: async (context, event) => {
            return new Promise((resolve, reject) => {
                VanguardService.schema.getEntities().then((newEntities) => {
                    resolve({
                        loadedData: newEntities.response
                    });
                });
            });
        },
        loadDataFilteredForTransientData: async (context, event) => {
            return new Promise((resolve, reject) => {
                VanguardService.schema.getEntities().then((newEntities) => {
                    resolve({
                        loadedData: newEntities.response
                    });
                });
            });
        }
    }
};

/**
 * @type {SweftDataMachineEntitySchemaDataActorMachine}
 */
export const entitySchemaDataActorMachine = generateOptimisticDataMachine(entitySchemaMachineGeneratorProps);
