import request from "./request";
import { v4 as uuidv4 } from "uuid";
import { baseConfig } from "@app/config/baseConfig";
import { SweftError } from "@app/common/SweftError";

const commandsApi = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/commands`;
const alertsApi = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/alerts`;

export const AlertService = {
    /**
     * Check status of the alerts service
     * Gives a 401 when no session is available
     * @returns {Promise<unknown>}
     */
    status: async () => {
        try {
            return await request({
                method: "GET",
                url: `${alertsApi}/status`,
            });
        } catch (error) {
            throw new SweftError(`Error getting alert service status`, error);
        }
    },
    saveAlert: async (alert) => {
        if (!alert) {
            throw new SweftError("No alert passed in to save");
        }

        const { entity, alertId } = alert;

        if (!alertId) {
            alert.alertId = uuidv4();
        }

        if (!entity) {
            throw new Error("Entity is required to create an alert");
        }

        try {
            const url = `${commandsApi}/alert/${entity}/${alertId}`;
            const response = await request({
                method: "PUT",
                url,
                data: alert,
            });

            return response.data;
        } catch (error) {
            throw new SweftError(
                `Error saving the alert with alert id '${alertId}'`,
                error
            );
        }
    },

    getAlert: async (alertId) => {
        try {
            const response = await request({
                method: "GET",
                url: `${alertsApi}/${alertId}`,
            });

            return response.data;
        } catch (error) {
            throw new SweftError(
                `Error getting the alert with alert id '${alertId}'`,
                error
            );
        }
    },
    getAlerts: async () => {
        try {
            const response = await request({
                method: "GET",
                url: `${alertsApi}`,
            });
            return response.data.data;
        } catch (error) {
            throw new SweftError(
                `Error getting alert list from the alert service`,
                error
            );
        }
    },
    removeAlert: async (alert) => {
        if (!alert || !alert.alertId || !alert.entity) {
            throw new SweftError(
                "Alert ID and entity are required to remove an alert"
            );
        }

        try {
            const { entity, alertId } = alert;
            const response = await request({
                method: "DELETE",
                url: `${commandsApi}/alert/${entity}/${alertId}`,
            });

            return response.data;
        } catch (error) {
            throw new SweftError(
                `Error removing the alert with alert id '${alert.alertId}'`,
                error
            );
        }
    },

    getEntityAlerts: async (entityType) => {
        const response = await request({
            method: "GET",
            url: `${alertsApi}/list/${entityType}`,
        });

        return response.data;
    },
};
