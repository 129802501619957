// @ts-check
import request from './request';
import { baseConfig } from '@app/config/baseConfig';

export const api = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/auth`;

/**
 * Create an authentication-only user, sweft should handle user info beyond auth
 */
const AuthService = {
    /**
     * Check status of the auth service and whether client has a session or not
     * Gives a 401 when no session is available
     * @returns {Promise<{}>}
     */
    status: async () => {
        return request({
            method: "GET",
            url: `${api}/status`
        });
    },

    /**
     * Note: Signin is handled by the auth react component, so we do not expose it to the api here
     */

    /**
     * This is the mechanism for creating new credentials, so an admin might create new credentials
     * Create a single auth-only session
     * @TODO design how to handle external user profile info
     * @param {*} emailAndPasswordInfo
     */
    createAuthUser: async (emailAndPasswordInfo) => {
        const throwOnBlank = (check) => {
            if (check === '' || check === undefined || check === null) {
                throw new Error('Invalid blank value when creating a new user');
            }
            return check;
        };
        throwOnBlank(emailAndPasswordInfo?.email);
        throwOnBlank(emailAndPasswordInfo?.password);

        return request({
            data: {
                "formFields": [
                    {
                        "id": "email",
                        "value": emailAndPasswordInfo.email
                    },
                    {
                        "id": "password",
                        "value": emailAndPasswordInfo.password
                    }
                ]
            },
            method: "POST",
            url: `${api}/auth/signup`
        });
    },

    /**
     * Get a view of currently logged in auth session info
     * @returns {Promise<{data: CredentialedUser}>}
     */
    selfInfo: async () => {
        try {
            const userData = await request({
                method: "GET",
                url: `${api}/self-info`
            });
            if (!userData.data.status) {
                throw ({ errorCode: 400, title: "Unauthorized", message: "Please contact your SWEFT administrator." });
            }

            return userData;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    /**
     * Request a password reset email
     * @param {string} email
     */
    requestPasswordReset: async (email) => {
        // validate email format
        if (!email || !email.includes('@')) {
            throw new Error('Invalid email format');
        }
        try {
            return await request({
                method: 'POST',
                data: {
                    "formFields": [
                        {
                            "id": "email",
                            "value": email
                        }
                    ]
                },
                url: `${api}/auth/user/password/reset/token`
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
};

export default AuthService;
