import { isObject as sweftIsObject } from "@app/common/utils";
import { evaluateSingleBobjWithFormulaList } from "@app/data/utils";
import { mergeWith, isObject as lodashIsObject } from "lodash";

export const generateTransientObject = ({ context, event, transientConfig, transientValueGetter }) => {
    const transientObject = {
        ...event.object,
        ...(event?.fieldBeingUpdated ?? {}),
        ...(event?.expectedUpdateResult ?? {}),
    };
    const { projectionAttributeList = [], entityFormulaPathMap = [] } = context || {};
    const formulaToEvaluateList = projectionAttributeList.reduce((formulaList, nextProjection) => {
        const projectionFormulaObj = entityFormulaPathMap?.[nextProjection] ?? null;
        if (!projectionFormulaObj) {
            return formulaList;
        }
        return [
            ...formulaList,
            projectionFormulaObj
        ];
    }, []);

    transientObject[transientConfig.transientProperty] = transientValueGetter(transientObject);
    if (transientConfig.evaluateTransientObject === false) {
        return transientObject;
    }

    return evaluateSingleBobjWithFormulaList({ bobj: transientObject, formulaList: formulaToEvaluateList });
};

export const generateTransientObjectList = ({ context, event, transientConfig, transientValueGetter }) => {
    const { objectList } = event;
    const { projectionAttributeList = [], entityFormulaPathMap = [] } = context || {};
    const formulaToEvaluateList = projectionAttributeList.reduce((formulaList, nextProjection) => {
        const projectionFormulaObj = entityFormulaPathMap?.[nextProjection] ?? null;
        if (!projectionFormulaObj) {
            return formulaList;
        }
        return [
            ...formulaList,
            projectionFormulaObj
        ];
    }, []);

    return objectList.map((object) => {
        const transientObject = object;
        transientObject[transientConfig.transientProperty] = transientValueGetter(transientObject);
        if (transientConfig.evaluateTransientObject === false) {
            return transientObject;
        }

        return evaluateSingleBobjWithFormulaList({ bobj: transientObject, formulaList: formulaToEvaluateList });
    });
};

const mergeBobjCustomizerWithKeyProperty = (keyProperty) => (newBobjValue, currentBobjValue) => {
    if (!lodashIsObject(newBobjValue) && !lodashIsObject(currentBobjValue)) {
        return newBobjValue;
    }
    const _newBobjValueIsObj = sweftIsObject(newBobjValue);
    const _currentBobjValueIsObj = sweftIsObject(currentBobjValue);

    if (_newBobjValueIsObj && _currentBobjValueIsObj) {
        // eslint-disable-next-line no-use-before-define
        return mergeBobj({ currentBobj: currentBobjValue, newBobj: newBobjValue, keyProperty });
    }

    if (_newBobjValueIsObj) {
        if (typeof _currentBobjValueIsObj === "string") {
            return newBobjValue;
        }
    } else if (typeof newBobjValue === "string") {
        return currentBobjValue;
    }

    const _newBobjValueIsArr = Array.isArray(newBobjValue);
    const _currentBojValueIsArr = Array.isArray(currentBobjValue);

    if (_newBobjValueIsArr && _currentBojValueIsArr) {
        return newBobjValue.map((newNestedObj) => {
            const currentNestedObj = currentBobjValue.find((obj) => newNestedObj && obj && newNestedObj[keyProperty] === obj[keyProperty]);
            if (!currentNestedObj) {
                return newNestedObj;
            }
            // eslint-disable-next-line no-use-before-define
            return mergeBobj({ currentBobj: currentNestedObj, newBobj: newNestedObj, keyProperty });
        });
    }

    return newBobjValue;
};

export const mergeBobj = ({ currentBobj, newBobj, keyProperty }) => {
    return mergeWith(newBobj, currentBobj, mergeBobjCustomizerWithKeyProperty(keyProperty));
};

export const getFormulaToEvaluateList = ({ projectionAttributeList = [], entityFormulaPathMap = {} } = {}) => projectionAttributeList.reduce((formulaList, nextProjection) => {
    const projectionFormulaObj = entityFormulaPathMap[nextProjection];
    if (!projectionFormulaObj) {
        return formulaList;
    }
    return [...formulaList, projectionFormulaObj];
}, []);

