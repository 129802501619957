/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
// @ts-check
/**
 * A machine for loading, updating, and creating business objects of an entity
 * @typedef SweftDataMachineEntityDataActorMachine
 * @type {StateMachine<SweftDataUnknownDataActorMachineContext, none, SweftDataUnknownDataActorMachineTypestate>}
 */
import { VanguardService } from "@app/services/vanguardService";
import { SequencesService } from "@app/services/sequencesService";
import { generateOptimisticDataMachine } from "@app/data/machine/actors/optimisticDataActorMachine";
import { mapEntityData } from "@app/data/mapping/mapEntityData";
import { loadEntityDataFilteredForTransientData } from "@app/data/machine/actors/entityDataActorMachine/services/loadEntityDataFilteredForTransientData";

/**
 * @interface SweftDataMachineEntityDataActorMachineContext
 * @extends SweftOptimisticDataMachineContext
 * @prop {"id"} keyProperty
 */


/**
 * @param entity
 * @param type
 * @returns {SweftOptimisticDataMachineGeneratorProps}
 */
export const generateEntityDataMachineGeneratorProps = ({ dataActorOptions }) => {
    const { entity, type, schemaTreeExclusionList, relatedProjectionPaths, projectionAttributeList, loadOnSpawn, noParent, dataActorId, dataActorSavedQueryObject, receiveEvaluatedBobj, entityFormulaPathMap, formulaForBackendEval, dataMappingModuleConfig, alertMappingModuleConfig, preFilterEntityMap, savedQuery, additionalSavedQueryList } = dataActorOptions;

    const keyProperty = "id";
    return {
        dataActorId,
        loadOnSpawn,
        savedQuery,
        entity,
        type: type ?? `${entity}DataActor`,
        keyProperty,
        noParent,
        projectionAttributeList,
        receiveEvaluatedBobj,
        entityFormulaPathMap,
        preFilterEntityMap,
        formulaForBackendEval,
        createMachineOptions: {
            services: {
                createService: async (context) => {
                    const { object } = context;
                    return new Promise((resolve, reject) => {
                        VanguardService.data.createBusinessObject({ businessObject: object })
                            .then(() => {
                                resolve({
                                    newObject: {
                                        ...object,
                                    }
                                });
                            });
                    });
                },
                generatedIdService: async (context) => {
                    const { gidAttributesList, object } = context;
                    /**
                     * Returns the object with the generated id properties populated with the next sequence number.
                     */
                    const generatedIdAttributeList = await Promise.all(gidAttributesList.map((attribute) => {
                        return new Promise((resolve, reject) => {
                            SequencesService.data.generatedIdService({ attribute })
                                .then((sequenceNumber) => {
                                    resolve({
                                        key: attribute.name,
                                        value: sequenceNumber
                                    });
                                });
                        });
                    }));

                    const generatedIdObject = generatedIdAttributeList.reduce((currentObjVal, nextGeneratedIdObj) => {
                        return {
                            ...currentObjVal,
                            [nextGeneratedIdObj.key]: nextGeneratedIdObj.value
                        };
                    }, {});

                    return {
                        newObject: {
                            ...object,
                            ...generatedIdObject
                        },
                        generatedIdObject
                    };
                }
            }
        },
        createInBatchMachineOptions: {
            services: {
                createService: async (context) => {
                    console.log("in createInBatchMachineOptions/services/createService");

                    const { objectList } = context;
                    return new Promise((resolve, reject) => {
                        VanguardService.data.createBusinessObjectInBatch({ businessObjectList: objectList, entity })
                            .then(() => {
                                resolve({
                                    newObjectList: objectList
                                });
                            });
                    });
                }
            }
        },
        updateMachineOptions: {
            services: {
                updateService: async (context) => {
                    const { object, fieldBeingUpdated } = context;
                    return new Promise((resolve, reject) => {
                        VanguardService.data.updateBusinessObject({ businessObject: object, fieldBeingUpdated, keyProperty })
                            .then(() => {
                                resolve({
                                    newObject: {
                                        ...context
                                    }
                                });
                            });
                    });
                }
            }
        },
        updateInBatchMachineOptions: {
            services: {
                updateService: async (context) => {
                    const { objectList } = context;
                    return new Promise((resolve, reject) => {
                        VanguardService.data.updateBusinessObjectInBatch({ businessObjectList: objectList, entity })
                            .then(() => {
                                resolve({
                                    newObjectList: objectList
                                });
                            });
                    });
                }
            }
        },
        loadMachineOptions: {
            services: {
                /**
                 * Called when the LOAD_DATA event is received
                 */
                loadService: async (context, event) => {
                    const { projectionAttributeList: projectionAttributeListFromContext = [], additionalJsonLogicQueryObject: additionalJsonLogicQueryObjectFromContext, preFilterEntityMap: preFilterEntityMapFromContext } = context;

                    const additionalJsonLogicQueryObjectList = [];
                    if (dataActorSavedQueryObject) {
                        additionalJsonLogicQueryObjectList.push(dataActorSavedQueryObject);
                    }
                    if (additionalJsonLogicQueryObjectFromContext) {
                        additionalJsonLogicQueryObjectList.push(additionalJsonLogicQueryObjectFromContext);
                    }

                    let additionalJsonLogicQueryObject = null;
                    if (additionalJsonLogicQueryObjectList.length > 0) {
                        additionalJsonLogicQueryObject = { "and": additionalJsonLogicQueryObjectList };
                    }

                    let projectionAttributeListForLoadService = [...projectionAttributeList, ...projectionAttributeListFromContext];
                    let entityToLoad = entity;

                    if (dataMappingModuleConfig?.enabled === true) {
                        const { entityDataMappingConfigMap, alternateWorkspaceEntityToLoad } = dataMappingModuleConfig.options;
                        entityToLoad = alternateWorkspaceEntityToLoad;
                        projectionAttributeListForLoadService = [...projectionAttributeListForLoadService, ...entityDataMappingConfigMap[entityToLoad].mappingProjectionList];
                    }

                    if (alertMappingModuleConfig?.enabled === true) {
                        const { entityDataMappingConfigMap } = alertMappingModuleConfig.options;
                        if (entityDataMappingConfigMap?.[entityToLoad]) {
                            projectionAttributeListForLoadService = [...projectionAttributeListForLoadService, ...entityDataMappingConfigMap[entityToLoad]?.mappingProjectionList];
                        }
                        projectionAttributeListForLoadService = [...projectionAttributeListForLoadService];
                    }

                    const projectionAttributeListWithoutFormulaList = projectionAttributeListForLoadService.filter((projection) => !entityFormulaPathMap?.[projection]);

                    let additionalSavedQueryIdList;
                    if (additionalSavedQueryList) {
                        additionalSavedQueryIdList = additionalSavedQueryList?.map(({ id }) => id);
                    }

                    const newObjs = await VanguardService.data.getBusinessObjectsOfEntity({
                        entity: entityToLoad,
                        schemaTreeExclusionList,
                        relatedProjectionPaths,
                        projectionAttributeList: projectionAttributeListWithoutFormulaList,
                        additionalJsonLogicQueryObject,
                        receiveEvaluatedBobj,
                        formulaForBackendEval,
                        ...(preFilterEntityMap ?
                            preFilterEntityMap :
                            { preFilterEntityMap: preFilterEntityMapFromContext }),
                        savedQueryId: savedQuery?.id,
                        additionalSavedQueryIdList
                    });

                    if (alertMappingModuleConfig?.enabled === true) {
                        const { entityDataMappingConfigMap } = alertMappingModuleConfig.options;
                        const entityDataMappingConfigObj = entityDataMappingConfigMap[entityToLoad];
                        // return newObjs if API call fails the error is returned here.
                        if (!Array.isArray(newObjs) || !entityDataMappingConfigObj) {
                            return { loadedData: newObjs };
                        }
                        const mappedData = mapEntityData({ entityDataMappingConfigObj, bobjList: newObjs });
                        return { loadedData: mappedData, requestProjectionAttributeList: projectionAttributeListForLoadService, alertMappingModuleConfig };
                    }

                    if (dataMappingModuleConfig?.enabled === true) {
                        const { entityDataMappingConfigMap } = dataMappingModuleConfig.options;
                        const entityDataMappingConfigObj = entityDataMappingConfigMap[entityToLoad];
                        // return newObjs if API call fails the error is returned here.
                        if (!Array.isArray(newObjs)) {
                            return { loadedData: newObjs };
                        }
                        const mappedData = mapEntityData({ entityDataMappingConfigObj, bobjList: newObjs });
                        return { loadedData: mappedData, requestProjectionAttributeList: projectionAttributeListForLoadService, dataMappingModuleConfig };
                    }

                    return { loadedData: newObjs, requestProjectionAttributeList: projectionAttributeListForLoadService };
                },
            }
        },
        services: {
            /**
             * Called by the machine when loading on spawn
             */
            loadService: async (context, event) => {
                const { projectionAttributeList: projectionAttributeListFromContext = [] } = context;
                let additionalJsonLogicQueryObject = null;
                if (dataActorSavedQueryObject) {
                    additionalJsonLogicQueryObject = dataActorSavedQueryObject;
                }

                let entityToLoad = entity;
                let projectionAttributeListForLoadService = [...projectionAttributeListFromContext];
                if (dataMappingModuleConfig?.enabled === true) {
                    const { entityDataMappingConfigMap, alternateWorkspaceEntityToLoad } = dataMappingModuleConfig.options;
                    entityToLoad = alternateWorkspaceEntityToLoad;
                    projectionAttributeListForLoadService = [...projectionAttributeListForLoadService, ...entityDataMappingConfigMap[entityToLoad].mappingProjectionList];
                }

                if (alertMappingModuleConfig?.enabled === true) {
                    const { entityDataMappingConfigMap } = alertMappingModuleConfig.options;
                    if (entityDataMappingConfigMap?.[entityToLoad]) {
                        projectionAttributeListForLoadService = [...projectionAttributeListForLoadService, ...entityDataMappingConfigMap[entityToLoad]?.mappingProjectionList];
                    }
                    projectionAttributeListForLoadService = [...projectionAttributeListForLoadService];
                }

                const projectionAttributeListWithoutFormulaList = projectionAttributeListForLoadService.filter((projection) => !entityFormulaPathMap?.[projection]);

                let additionalSavedQueryIdList;
                if (additionalSavedQueryList) {
                    additionalSavedQueryIdList = additionalSavedQueryList?.map(({ id }) => id);
                }

                const newObjs = await VanguardService.data.getBusinessObjectsOfEntity({
                    entity: entityToLoad,
                    schemaTreeExclusionList,
                    relatedProjectionPaths,
                    projectionAttributeList: projectionAttributeListWithoutFormulaList,
                    additionalJsonLogicQueryObject,
                    receiveEvaluatedBobj,
                    formulaForBackendEval,
                    preFilterEntityMap,
                    savedQueryId: savedQuery?.id,
                    additionalSavedQueryIdList
                });

                if (alertMappingModuleConfig?.enabled === true) {
                    const { entityDataMappingConfigMap } = alertMappingModuleConfig.options;
                    const entityDataMappingConfigObj = entityDataMappingConfigMap[entityToLoad];
                    // return newObjs if API call fails the error is returned here.
                    if (!Array.isArray(newObjs) || !entityDataMappingConfigObj) {
                        return { loadedData: newObjs };
                    }
                    const mappedData = mapEntityData({ entityDataMappingConfigObj, bobjList: newObjs });
                    return { loadedData: mappedData, requestProjectionAttributeList: projectionAttributeListForLoadService, alertMappingModuleConfig };
                }

                if (dataMappingModuleConfig?.enabled === true) {
                    const { entityDataMappingConfigMap } = dataMappingModuleConfig.options;
                    const entityDataMappingConfigObj = entityDataMappingConfigMap[entityToLoad];
                    // return newObjs if API call fails the error is returned here.
                    if (!Array.isArray(newObjs)) {
                        return { loadedData: newObjs };
                    }
                    const mappedData = mapEntityData({ entityDataMappingConfigObj, bobjList: newObjs });
                    return { loadedData: mappedData, requestProjectionAttributeList: projectionAttributeListForLoadService, dataMappingModuleConfig };
                }

                return {
                    loadedData: newObjs,
                    requestProjectionAttributeList: projectionAttributeListForLoadService
                };
            },
            loadDataFilteredForTransientData: async (context) => await loadEntityDataFilteredForTransientData({ context }, { entity, entityFormulaPathMap, receiveEvaluatedBobj, schemaTreeExclusionList }) }
    };
};

/**
 * @type {SweftDataMachineEntityDataActorMachine}
 */
export const generateEntityDataActorMachine = ({ dataActorOptions }) => generateOptimisticDataMachine(generateEntityDataMachineGeneratorProps({ dataActorOptions }));
