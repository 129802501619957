import React from "react";

/**
 * Simple skeleton dependent only on tailwind and css
 * @returns {JSX.Element}
 */
export const SimpleSkeleton = ({ className }) => {
    return (
        <div role="status" className={`simple-skeleton max-w-sm animate-pulse align-bottom ${className || ''}`}>
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
            <span className="sr-only">Loading...</span>
        </div>
    );
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const LoadingArea = ({ className = '' }) => {
    return (
        <div className="flex h-screen">
            <div className="m-auto">
                <SimpleSkeleton className={`loading-area-skeleton ${className ?? ''}`} />
            </div>
        </div>
    );
};
