// @ts-check
import React from "react";
import { LoginHeader } from "./LoginHeader";
import { LoginFooter } from "./LoginFooter";
import SuperTokens from "supertokens-auth-react";
import EmailPassword, { redirectToAuth } from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import { baseConfig } from '../../config/baseConfig';

export const initialize = () => {
    SuperTokens.init({ // Our auth service
        appInfo: {
            // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
            appName: baseConfig.AUTH_APP_NAME,
            apiDomain: baseConfig.AUTH_API_DOMAIN,
            websiteDomain: baseConfig.WEBSITE_DOMAIN,
            apiBasePath: baseConfig.AUTH_API_BASE_PATH,
            websiteBasePath: baseConfig.AUTH_WEBSITE_BASE_PATH,
        },
        recipeList: [
            EmailPassword.init({
                // TODO: Implement verification email
                emailVerificationFeature: {
                    mode: "OFF"
                },
                cookieDomain: `.${baseConfig.GATEWAY_API_BASE}`,
                useShadowDom: false, // Allow password managers to work with this UI
                override: {
                    components: {
                        // Only remove the signup link when signup is actually validly disabled
                        EmailPasswordSignInHeader_Override: ({ DefaultComponent, ...props }) => {
                            return (
                                <>
                                    <LoginHeader altText="Sweft" />
                                    <DefaultComponent {...props} />
                                    <p>Welcome, please sign in</p>
                                </>
                            );
                        },
                        EmailPasswordSignIn_Override: ({ DefaultComponent, ...props }) => {
                            return (
                                <>
                                    <DefaultComponent {...props} />
                                    <LoginFooter buildVersion={BUILD_VER} />
                                </>
                            );
                        },
                        EmailPasswordResetPasswordEmail_Override: ({ DefaultComponent, ...props }) => {
                            return (
                                <>
                                    <div className="mt-5">
                                        <LoginHeader altText="Sweft Forgot Password" variant="sweft-login-forgot-password" />
                                    </div>
                                    <DefaultComponent {...props} />
                                    <LoginFooter buildVersion="" />
                                </>
                            );
                        },
                        EmailPasswordSubmitNewPassword_Override: ({ DefaultComponent, ...props }) => {
                            return (
                                <>
                                    <div className="mt-5">
                                        <LoginHeader altText="Sweft Change Password" variant="sweft-change-password" />
                                    </div>
                                    <DefaultComponent {...props} />
                                    <LoginFooter buildVersion="" />
                                </>
                            );
                        }
                    }
                },
                style: {
                    superTokensBranding: {
                        "display": "none",
                    },
                    button: {
                        backgroundColor: "#F15A29",
                    },
                    // Only remove the signup link when signup is actually validly disabled
                    headerSubtitle: {
                        display: 'none',
                    }
                }
            }),
            Session.init({
                cookieDomain: `.${baseConfig.GATEWAY_API_BASE}`,
            })
        ]
    });
};
