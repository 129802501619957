// @ts-check
import React, { createContext, useContext, useMemo } from "react";
import { useInterpret } from "@xstate/react";
import { createDataMachine } from "@app/data/machine";

export const DataContext = createContext({});

export const useData = () => useContext(DataContext);

/**
 * A global React context provider for data management on the Sweft app.
 * Data can be loaded into the app via actors of the data machine.
 * The data machine service is immutable and will not trigger re-renders downstream.
 * Consumers of this React context can access data actors of the data machine through the data machine service.
 * It is recommended to use the useDataActor hook for accessing data.
 * When it is not feasible to access data from the hook, then use the getActorDataFromMachineService utility.
 * @prop children
 * @returns {JSX.Element}
 * @constructor
 */
export const DataWrapper = ({ children, alertsDataActorMachineOptions }) => {
    const dataMachine = useMemo(() => createDataMachine({ alertsDataActorMachineOptions }), [alertsDataActorMachineOptions]);

    /**
     * @typedef SweftDataMachineService
     * @type {InterpreterFrom<SweftDataMachine>}
     */
    const dataMachineService = useInterpret(dataMachine, { devTools: true });
    const { send: sendToDataMachineService } = dataMachineService;
    const contextValue = useMemo(() => ({
        dataMachineService,
        sendToDataMachineService
    }), [dataMachineService, sendToDataMachineService]);


    return (
        <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
    );
};
