import request from './request';
import { headers } from './headers';
import { baseConfig } from '@app/config/baseConfig';

export const api = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/messages`;

const MessagesService = {
    /**
     * Check status of the service
     * @returns {Promise<{}>}
     */
    status: async () => {
        if (!api) {
            throw new Error("Sweft Error 1694: Unable to check status as api base is not set");
        }
        return request({
            method: "GET",
            url: `${api}/status`
        });
    },
    createMessages: (data) => {
        return request({
            headers: headers,
            method: "PUT",
            url: `${api}/${data.messageId}`,
            data: data
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    },
    getMessageByObjectId: (objectId) => {
        return request({
            headers: headers,
            method: "GET",
            url: `${api}/?objectId=${objectId}`,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    },
    getMessageByRecipientIdAndStatus: (userId, status) => {
        return request({
            headers: headers,
            method: "GET",
            url: `${api}/?recipientId=${userId}&readStatus=${status}`,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    },
    toggleReadStatus: (data) => {
        return request({
            headers: headers,
            method: "POST",
            url: `${api}/${data.messageId}/toggleReadStatus`,
            data: data,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }
};

export default MessagesService;
